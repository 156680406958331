import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'eze-modal-header',
	template: '<ng-content></ng-content>',
	styles: []
})
export class ModalHeaderComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}

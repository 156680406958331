import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	Resolve,
	ActivatedRouteSnapshot
} from '@angular/router';
import { take, map } from 'rxjs/operators';

import { EventsService } from '../../services/events-service/events.service';

@Injectable()
export class EventResolverService implements Resolve<any> {
	constructor(private eventsService: EventsService) {}

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		const id = route.paramMap.get('id');

		return this.eventsService.getEventData(id).pipe(
			take(1),
			map(eventData => {
				if (eventData) {
					return eventData;
				} else {
					return null;
				}
			})
		);
	}
}

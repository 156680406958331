import { OnInit, ElementRef } from '@angular/core';
var EzModalComponent = /** @class */ (function () {
    function EzModalComponent() {
        this.canDismiss = true;
        this._isOpen = false;
    }
    Object.defineProperty(EzModalComponent.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: true,
        configurable: true
    });
    EzModalComponent.prototype.ngOnInit = function () { };
    EzModalComponent.prototype.handleOutsideClick = function (event) {
        if (!this.canDismiss) {
            return;
        }
        if (this.modalContainerElement.nativeElement === event.target) {
            this._isOpen = false;
        }
    };
    EzModalComponent.prototype.open = function () {
        this._isOpen = true;
    };
    EzModalComponent.prototype.close = function () {
        this._isOpen = false;
    };
    return EzModalComponent;
}());
export { EzModalComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal-footer.component";
var styles_ModalFooterComponent = [];
var RenderType_ModalFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalFooterComponent, data: {} });
export { RenderType_ModalFooterComponent as RenderType_ModalFooterComponent };
export function View_ModalFooterComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_ModalFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "eze-modal-footer", [], null, null, null, View_ModalFooterComponent_0, RenderType_ModalFooterComponent)), i0.ɵdid(1, 114688, null, 0, i1.ModalFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalFooterComponentNgFactory = i0.ɵccf("eze-modal-footer", i1.ModalFooterComponent, View_ModalFooterComponent_Host_0, {}, {}, ["*"]);
export { ModalFooterComponentNgFactory as ModalFooterComponentNgFactory };

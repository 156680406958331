var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ProfileService } from '../../core/services/profile-service/profile.service';
var MyEventCardComponent = /** @class */ (function () {
    function MyEventCardComponent(profileService) {
        this.profileService = profileService;
        this.showDashboardLink = false;
        this.showDayOfLink = false;
    }
    Object.defineProperty(MyEventCardComponent.prototype, "event", {
        get: function () {
            return this._event;
        },
        set: function (input) {
            this._event = __assign({}, input);
            this._event.deadlinesArr = Object.keys(input.deadlines).map(function (key) {
                return {
                    registrationType: key,
                    deadline: input.deadlines[key],
                };
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MyEventCardComponent.prototype, "documents", {
        get: function () {
            return this._event.documents || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MyEventCardComponent.prototype, "divisionSheetsHref", {
        get: function () {
            return "/api/v1/ez/events/" + this.event.code + "/division_sheets";
        },
        enumerable: true,
        configurable: true
    });
    MyEventCardComponent.prototype.ngOnInit = function () { };
    MyEventCardComponent.prototype.getRegistrationLink = function (deadline) {
        return window.location.origin + "/events/" + this.event.code + "/registrations/" + deadline.registrationType;
    };
    MyEventCardComponent.prototype.copyInputMessage = function (inputElement) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
    };
    MyEventCardComponent.prototype.capFirst = function (str) {
        return str[0].toUpperCase() + str.slice(1);
    };
    return MyEventCardComponent;
}());
export { MyEventCardComponent };

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';

declare const paypal;

@Component({
	selector: 'eze-paypal-button',
	templateUrl: './paypal-button.component.html',
	styleUrls: ['./paypal-button.component.css']
})
export class PaypalButtonComponent implements OnInit {
	@Input() payload;
	@Input() eventId;
	@Input() entity = '';
	private id = '';
	@Output() success = new EventEmitter<any>();
	@Output() error = new EventEmitter<any>();

	get environment(): string {
		if (environment.production) {
			return 'production';
		} else {
			return 'sandbox';
		}
	}

	get EXECUTE_PAYMENT_URL(): string {
		return `/api/v1/events/${this.eventId}/${this.entity}/${this.id}/paypal-execute`;
	}

	get CREATE_PAYMENT_URL(): string {
		return `/api/v1/events/${this.eventId}/${this.entity}`;
	}

	constructor() {}

	ngOnInit() {
		paypal.Button.render(
			{
				env: this.environment, // Or 'sandbox'

				commit: true, // Show a 'Pay Now' button

				payment: () => {
					return paypal
						.request({
							method: 'post',
							url: this.CREATE_PAYMENT_URL,
							json: this.payload
						})
						.then(data => {
							this.id = data.id;
							// save id for generating execute_payment_url and i dunno, stuff
							return data.payment_id || data.payment.id;
						});
				},

				onAuthorize: data => {
					return paypal
						.request({
							method: 'post',
							url: this.EXECUTE_PAYMENT_URL,
							json: {
								payment_id: data.paymentID,
								payer_id: data.payerID
							}
						})
						.then(res => {
							// Cool display that html junk like before
							this.success.emit(res._html_message);
						})
						.catch(err => {
							this.error.emit(err);
						});
				}
			},
			'#paypal-button'
		);
	}
}

import { OnInit, AfterContentInit } from '@angular/core';
import { InputDirective } from './input.directive';
var InputContainerComponent = /** @class */ (function () {
    function InputContainerComponent() {
        this.label = '';
        this._required = false;
    }
    Object.defineProperty(InputContainerComponent.prototype, "required", {
        get: function () {
            return this._required || this.inputElement.required;
        },
        set: function (input) {
            if (input === '' || input === 'true' || input === true) {
                this._required = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    InputContainerComponent.prototype.ngOnInit = function () {
    };
    InputContainerComponent.prototype.ngAfterContentInit = function () {
        this.autoSetLabel(this.inputElement.id);
    };
    InputContainerComponent.prototype.autoSetLabel = function (str) {
        if (this.label === '') {
            this.label = str.charAt(0).toUpperCase() + str.slice(1).replace('_', ' ');
        }
    };
    Object.defineProperty(InputContainerComponent.prototype, "labelClass", {
        get: function () {
            return this.inputElement.inputType.toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputContainerComponent.prototype, "showIconElement", {
        get: function () {
            return this.isSelectElement || this.isCheckboxElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputContainerComponent.prototype, "isSelectElement", {
        get: function () {
            return this.inputElement.inputType.toLowerCase() === 'select';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputContainerComponent.prototype, "isCheckboxElement", {
        get: function () {
            return this.inputElement.inputType.toLowerCase() === 'checkbox';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputContainerComponent.prototype, "showError", {
        get: function () {
            return this.inputElement.showError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputContainerComponent.prototype, "errorMessages", {
        get: function () {
            return this.inputElement.errors;
        },
        enumerable: true,
        configurable: true
    });
    return InputContainerComponent;
}());
export { InputContainerComponent };

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// modules
import { EzTabsetModule } from './ez-tabset';
import { EzModalModule } from './ez-modal';

// services
import {
	CalculateAge,
	_calculateAge,
	ScrollIt,
	_scrollIt,
	GenerateSchoolNameService,
} from './';

// components
import {
	SmartFormContainerComponent,
	ButtonComponent,
	InputContainerComponent,
	InputDirective,
	AlertComponent,
	ContactComponent,
	BirthDateComponent,
	EzPromoComponent,
	SideMenuComponent,
	PaypalButtonComponent,
	AuthorizeNetPaymentComponent,
	StripePaymentModalComponent,
	SectionTitleComponent,
	PaginationComponent,
	NewPasswordFormComponent,
	NavbarComponent,
	MomentDatePipe,
	MyEventCardComponent,
	MyRegistrationCardsComponent,
	DayOfSearchComponent,
	ListSearchFormComponent,
} from './';

// 3rd party modules
import { TextMaskModule } from 'angular2-text-mask';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from './helper-directives/click-outside.directive';
import { RegistrationCardEditFormComponent } from './registration-card-edit-form/registration-card-edit-form.component';

@NgModule({
	imports: [
		CommonModule,
		TextMaskModule,
		ReactiveFormsModule,
		FormsModule,
		RouterModule,
		EzModalModule,
	],
	declarations: [
		SmartFormContainerComponent,
		ButtonComponent,
		InputContainerComponent,
		InputDirective,
		AlertComponent,
		ContactComponent,
		BirthDateComponent,
		EzPromoComponent,
		SideMenuComponent,
		SectionTitleComponent,
		PaginationComponent,
		PaypalButtonComponent,
		NewPasswordFormComponent,
		AuthorizeNetPaymentComponent,
		StripePaymentModalComponent,
		ClickOutsideDirective,
		NavbarComponent,
		MomentDatePipe,
		MyEventCardComponent,
		MyRegistrationCardsComponent,
		DayOfSearchComponent,
		RegistrationCardEditFormComponent,
		ListSearchFormComponent,
	],
	exports: [
		TextMaskModule,
		ReactiveFormsModule,

		SmartFormContainerComponent,
		ButtonComponent,
		InputContainerComponent,
		InputDirective,
		AlertComponent,
		ContactComponent,
		BirthDateComponent,
		EzPromoComponent,
		SideMenuComponent,
		SectionTitleComponent,
		PaginationComponent,
		PaypalButtonComponent,
		AuthorizeNetPaymentComponent,
		StripePaymentModalComponent,
		ClickOutsideDirective,
		NavbarComponent,
		MomentDatePipe,
		EzTabsetModule,
		EzModalModule,
		MyEventCardComponent,
		MyRegistrationCardsComponent,
		DayOfSearchComponent,
		ListSearchFormComponent,
	],
	providers: [
		{ provide: CalculateAge, useValue: _calculateAge },
		{ provide: ScrollIt, useValue: _scrollIt },
		GenerateSchoolNameService,
	],
})
export class SharedModule {}

import { Injectable } from '@angular/core';
import {
	Router,
	Resolve,
	RouterStateSnapshot,
	ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';

import { ProfileService } from '../../services/profile-service/profile.service';

@Injectable()
export class ProfileResolverService implements Resolve<any> {
	constructor(
		private router: Router,
		private profileService: ProfileService
	) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> {
		return this.profileService.getProfile().pipe(
			take(1),
			catchError(() => of(null))
		);
	}
}

import { OnInit, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
var PaypalButtonComponent = /** @class */ (function () {
    function PaypalButtonComponent() {
        this.entity = '';
        this.id = '';
        this.success = new EventEmitter();
        this.error = new EventEmitter();
    }
    Object.defineProperty(PaypalButtonComponent.prototype, "environment", {
        get: function () {
            if (environment.production) {
                return 'production';
            }
            else {
                return 'sandbox';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaypalButtonComponent.prototype, "EXECUTE_PAYMENT_URL", {
        get: function () {
            return "/api/v1/events/" + this.eventId + "/" + this.entity + "/" + this.id + "/paypal-execute";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaypalButtonComponent.prototype, "CREATE_PAYMENT_URL", {
        get: function () {
            return "/api/v1/events/" + this.eventId + "/" + this.entity;
        },
        enumerable: true,
        configurable: true
    });
    PaypalButtonComponent.prototype.ngOnInit = function () {
        var _this = this;
        paypal.Button.render({
            env: this.environment,
            commit: true,
            payment: function () {
                return paypal
                    .request({
                    method: 'post',
                    url: _this.CREATE_PAYMENT_URL,
                    json: _this.payload
                })
                    .then(function (data) {
                    _this.id = data.id;
                    // save id for generating execute_payment_url and i dunno, stuff
                    return data.payment_id || data.payment.id;
                });
            },
            onAuthorize: function (data) {
                return paypal
                    .request({
                    method: 'post',
                    url: _this.EXECUTE_PAYMENT_URL,
                    json: {
                        payment_id: data.paymentID,
                        payer_id: data.payerID
                    }
                })
                    .then(function (res) {
                    // Cool display that html junk like before
                    _this.success.emit(res._html_message);
                })
                    .catch(function (err) {
                    _this.error.emit(err);
                });
            }
        }, '#paypal-button');
    };
    return PaypalButtonComponent;
}());
export { PaypalButtonComponent };

import { Validators } from '@angular/forms';

export class PersonalInfoForm {
	name_first = ['', Validators.required];
	name_middle = [''];
	name_last = ['', Validators.required];

	birth_date = ['', Validators.required];

	gender = ['', Validators.required];
}
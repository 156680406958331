import { OnInit, EventEmitter, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { mergeMap, tap } from 'rxjs/operators';
import { generateFutureYears, months, getCreditCardMask } from '../constants';
import { AuthorizeNetService } from '../../core/services/authorizenet-service/authorizenet.service';
import { EventsService } from '../../core/services/events-service/events.service';
import { AlertComponent } from '../alert/alert.component';
var AuthorizeNetPaymentComponent = /** @class */ (function () {
    function AuthorizeNetPaymentComponent(formBuilder, authroizeNetService, eventsService) {
        this.formBuilder = formBuilder;
        this.authroizeNetService = authroizeNetService;
        this.eventsService = eventsService;
        this.success = new EventEmitter();
        this.showValidation = false;
        this.years = [];
        this.submitting = false;
    }
    Object.defineProperty(AuthorizeNetPaymentComponent.prototype, "months", {
        get: function () {
            return months;
        },
        enumerable: true,
        configurable: true
    });
    AuthorizeNetPaymentComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    AuthorizeNetPaymentComponent.prototype.open = function () {
        this.modal.open();
    };
    AuthorizeNetPaymentComponent.prototype.onModalOpen = function () {
        this.alert.closeAlert();
        this.form.reset();
    };
    AuthorizeNetPaymentComponent.prototype.initForm = function () {
        this.form = this.formBuilder.group({
            fullName: ['', Validators.required],
            cardNumber: ['', Validators.required],
            month: ['', Validators.required],
            year: ['', Validators.required],
            cardCode: ['', Validators.required],
            zip: ['', Validators.required],
        });
        this.years = generateFutureYears();
    };
    AuthorizeNetPaymentComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.submitting) {
            return;
        }
        if (this.form.invalid) {
            this.alert.newAlert('Please fill out required fields', 'danger');
            this.showValidation = true;
            return;
        }
        var nonce = null;
        this.alert.newAlert('Submitting...', 'info');
        this.submitting = true;
        this.authroizeNetService
            .getAuthnetNonce(this.form.value)
            .pipe(mergeMap(function (nonceResponse) {
            nonce = nonceResponse;
            return _this.eventsService.postEntity(_this.eventId, _this.entity, _this.payload);
        }), mergeMap(function (competitor) {
            // TODO - not ANY!!!
            return _this.authroizeNetService.executePayment(_this.eventId, _this.entity, competitor.id, nonce);
        }), tap(function () { return (_this.submitting = false); }, function () { return (_this.submitting = false); }))
            .subscribe(function (res) {
            _this.success.next(res._html_message);
            _this.modal.close();
        }, function (err) {
            _this.alert.newAlert(_this.parseError(err) ||
                'There was an error in submitting your payment.', 'danger');
        });
    };
    AuthorizeNetPaymentComponent.prototype.getCardMask = function (formControl) {
        return getCreditCardMask(this.form.get(formControl).value);
    };
    AuthorizeNetPaymentComponent.prototype.parseError = function (err) {
        if (Array.isArray(err)) {
            return this.parseAcceptJsErrors(err);
        }
        return err && err.error && err.error.message;
    };
    AuthorizeNetPaymentComponent.prototype.parseAcceptJsErrors = function (errors) {
        return errors
            .map(function (error) {
            return error.text;
        })
            .join(' | ');
    };
    return AuthorizeNetPaymentComponent;
}());
export { AuthorizeNetPaymentComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
var EventsService = /** @class */ (function () {
    function EventsService(http) {
        this.http = http;
        this.eventData = null;
        this.waiver = {
            text: '',
            header: '',
        };
    }
    // refactor
    EventsService.prototype.getEventData = function (eventId) {
        var _this = this;
        this.getSchools(eventId).subscribe();
        return this.getEvent(eventId).pipe(map(function (res) {
            var output = __assign({}, res);
            var ranks = [];
            var value = output.ranks;
            for (var key in value) {
                if (value[key]) {
                    ranks.push({ value: key, desc: value[key] });
                }
            }
            output.ranks = ranks
                .map(function (rank) {
                rank.value = Number(rank.value);
                return rank;
            })
                .sort(function (a, b) {
                if (a.value > b.value) {
                    return 1;
                }
                if (a.value < b.value) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            var masterRanks = [];
            for (var key in output.masters.ranks) {
                if (output.masters.ranks[key]) {
                    masterRanks.push({
                        value: key,
                        desc: output.masters.ranks[key],
                    });
                }
            }
            output.masters.ranks = masterRanks
                .map(function (rank) {
                rank.value = Number(rank.value);
                return rank;
            })
                .sort(function (a, b) {
                if (a.value > b.value) {
                    return 1;
                }
                if (a.value < b.value) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            return output;
        }), tap(function (res) {
            _this.eventData = res;
        }));
    };
    // refactor
    EventsService.prototype.getEvent = function (eventId) {
        return this.http.get("/api/v1/events/" + eventId);
    };
    EventsService.prototype.getWaiverText = function (url) {
        return this.http.get(url, { responseType: 'text' });
    };
    EventsService.prototype.getSchools = function (eventId) {
        var _this = this;
        return this.http
            .get("/api/v1/events/" + eventId + "/schools")
            .pipe(tap(function (res) { return (_this.schools = res); }));
    };
    EventsService.prototype.getSchoolDetails = function (eventId, schoolId) {
        return this.http.get("/api/v1/events/" + eventId + "/schools/" + schoolId);
    };
    EventsService.prototype.postVolunteer = function (eventId, volunteer) {
        var _this = this;
        return this.http
            .post("/api/v1/events/" + eventId + "/volunteers", volunteer)
            .pipe(tap(function () { return _this.getSchools(eventId).subscribe(); }));
    };
    EventsService.prototype.postVIP = function (eventId, vip) {
        var _this = this;
        return this.http
            .post("/api/v1/events/" + eventId + "/vips", vip)
            .pipe(tap(function () { return _this.getSchools(eventId).subscribe(); }));
    };
    EventsService.prototype.postReferee = function (eventId, referee) {
        var _this = this;
        var payload = __assign({}, referee);
        // payload.hotel = payload.hotel ? 'Y' : 'N';
        return this.http
            .post("/api/v1/events/" + eventId + "/referees", payload)
            .pipe(tap(function () { return _this.getSchools(eventId).subscribe(); }));
    };
    EventsService.prototype.postCompetitor = function (eventId, competitor) {
        var _this = this;
        return this.http
            .post("/api/v1/events/" + eventId + "/competitors", competitor)
            .pipe(tap(function () { return _this.getSchools(eventId).subscribe(); }));
    };
    EventsService.prototype.postEntity = function (eventId, entity, payload) {
        var _this = this;
        return this.http
            .post("/api/v1/events/" + eventId + "/" + entity, payload)
            .pipe(tap(function () { return _this.getSchools(eventId).subscribe(); }));
    };
    EventsService.prototype.getDivisionData = function (eventId, rank, gender, birth_date, weight) {
        var genderValue = gender.toLocaleLowerCase() === 'male' ||
            gender.toLocaleLowerCase() === 'm'
            ? 'M'
            : 'F';
        var queryString = "rank=" + rank + "&gender=" + genderValue + "&birth_date=" + birth_date + "&weight=" + weight;
        return this.http.get("/api/v1/events/" + eventId + "/divisions?" + queryString);
    };
    EventsService.prototype.getPrice = function (eventId, divisionIds, schoolId) {
        if (!divisionIds || divisionIds.length === 0) {
            return of(0);
        }
        else {
            var queryString = encodeURI(divisionIds
                .map(function (d, i) {
                return "divisions[" + i + "]=" + d;
            })
                .join('&'));
            var url = "/api/v1/events/" + eventId + "/price?" + queryString;
            if (schoolId) {
                url += "&schoolId=" + schoolId;
            }
            return this.http.get(url);
        }
    };
    EventsService.prototype.getPriceAnyParams = function (eventId, params) {
        return this.http
            .get("/api/v1/events/" + eventId + "/price", { params: params })
            .pipe(map(function (res) { return res.price; }));
    };
    Object.defineProperty(EventsService.prototype, "ranks", {
        get: function () {
            return this.eventData.ranks;
        },
        enumerable: true,
        configurable: true
    });
    EventsService.prototype.isRegistrationClosed = function (formName) {
        var deadline = this.eventData.deadlines[formName];
        return deadline === 'closed';
    };
    EventsService.prototype.getDeadline = function (formName) {
        return new Date(this.eventData.deadlines[formName]);
    };
    EventsService.prototype.postRegistrationImage = function (eventId, formData) {
        return this.http.post("/api/v1/events/" + eventId + "/images", formData);
    };
    EventsService.prototype.postRegistrationDocument = function (eventId, formData) {
        return this.http.post("/api/v1/events/" + eventId + "/documents", formData);
    };
    EventsService.prototype.paymentCodeExecute = function (eventId, entity, entityId, payment_code) {
        return this.http.post("/api/v1/events/" + eventId + "/" + entity + "/" + entityId + "/paymentcode-execute", {
            payment_code: payment_code,
        });
    };
    EventsService.prototype.getEventRankFromRankNumber = function (rankNumber) {
        return this.eventData.ranks.find(function (rank) { return rank.value === rankNumber; });
    };
    EventsService.prototype.getApiDocs = function (eventId) {
        return this.http.get("/api/v1/ez/events/" + eventId + "/api_docs");
    };
    return EventsService;
}());
export { EventsService };

import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProfileService } from '../../services/profile-service/profile.service';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/profile-service/profile.service";
import * as i2 from "@angular/router";
var IsNotAuthenticatedGuard = /** @class */ (function () {
    function IsNotAuthenticatedGuard(profileService, router) {
        this.profileService = profileService;
        this.router = router;
    }
    IsNotAuthenticatedGuard.prototype.canActivate = function () {
        var _this = this;
        return this.profileService.getProfile().pipe(map(function () {
            _this.router.navigate(['ez']);
            return false;
        }), catchError(function () {
            return of(true);
        }));
    };
    IsNotAuthenticatedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsNotAuthenticatedGuard_Factory() { return new IsNotAuthenticatedGuard(i0.ɵɵinject(i1.ProfileService), i0.ɵɵinject(i2.Router)); }, token: IsNotAuthenticatedGuard, providedIn: "root" });
    return IsNotAuthenticatedGuard;
}());
export { IsNotAuthenticatedGuard };

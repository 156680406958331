var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BehaviorSubject } from 'rxjs';
import { tap, take, map, filter } from 'rxjs/operators';
import { EzHttpService } from '../ez-http/ez-http.service';
import { SessionService } from '../session-service/session.service';
// example permissions
// api_docs_view: true
// coach_edit: true
// coach_view: true
// competitor_edit: true
// competitor_view: true
// competitor_weighin: true
// ez2: true
// ez2_admin: true
// master_edit: false
// master_view: false
// owner_edit: false
// owner_view: false
// referee_edit: false
// referee_view: false
// volunteer_edit: false
// volunteer_view: false
var ProfileService = /** @class */ (function () {
    function ProfileService(ezHttp, sessionService) {
        this.ezHttp = ezHttp;
        this.sessionService = sessionService;
        this.url = '/api/v1/ez/me';
        this.profile = new BehaviorSubject(null);
        this.eventPermissions = new BehaviorSubject(null);
        this.isGuest = false;
    }
    ProfileService.prototype.getProfile = function () {
        var _this = this;
        return this.ezHttp.get(this.url).pipe(map(function (profile) {
            if (profile === null) {
                // logged in as guest
                return {};
            }
            var output = __assign({}, profile);
            output.gender = output.gender === 'M' ? 'Male' : 'Female';
            output.height_ft =
                output.height_ft === '0' ? '' : output.height_ft;
            output.height_in =
                output.height_in === '0' ? '' : output.height_in;
            output.weight = output.weight === '0.00' ? '' : output.weight;
            return output;
        }), tap(function (profile) { return _this.profile.next(profile); }), tap(function (profile) {
            var eventPermissions = {};
            profile.events.forEach(function (event) {
                eventPermissions[event.code] = event.permissions;
                // eventPermissions[event.code] = {
                // 	coach_view: true,
                // 	coach_edit: true,
                // 	competitor_view: true,
                // 	competitor_edit: true,
                // 	competitor_weighin: false,
                // 	master_view: false,
                // 	master_edit: false,
                // 	owner_view: false,
                // 	owner_edit: false,
                // 	referee_view: true,
                // 	referee_edit: true,
                // 	volunteer_view: false,
                // 	volunteer_edit: false,
                // 	api_docs_view: false,
                // 	reports_view: true,
                // 	reports_brackets: true,
                // 	ez2: true,
                // 	ez2_admin: true,
                // 	ez2_status: true,
                // 	ez2_checkin: true,
                // 	ez2_weighin: true,
                // 	ez2_brackets: true,
                // 	ez2_results: true,
                // 	ez2_schedule: true,
                // };
            });
            _this.eventPermissions.next(eventPermissions);
        }));
    };
    ProfileService.prototype.patchFormWithProfile = function (form) {
        this.profile
            .pipe(take(1), filter(function (data) { return !!data; }))
            .subscribe(function (data) {
            form.patchValue(data);
        });
    };
    ProfileService.prototype.logoutUser = function () {
        var _this = this;
        return this.sessionService.logout().pipe(tap(function () {
            _this.profile.next(null);
        }));
    };
    ProfileService.prototype.loginAsGuest = function () {
        this.isGuest = true;
        this.profile.next({});
    };
    ProfileService.prototype.hasPermission = function (eventCode, resource, actions) {
        var permissions = this.eventPermissions.value[eventCode];
        if (!permissions || !actions) {
            return false;
        }
        var returnValue = false;
        actions.forEach(function (action) {
            var permissionKey = resource + "_" + action;
            returnValue = returnValue || permissions[permissionKey];
        });
        return returnValue;
    };
    // TODO - fix me?
    ProfileService.prototype.hasMiscPersmission = function (eventCode, perm) {
        var permissions = this.eventPermissions.value[eventCode];
        if (!permissions) {
            return false;
        }
        return permissions[perm];
    };
    return ProfileService;
}());
export { ProfileService };

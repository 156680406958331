import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare const analytics;

@Component({
	selector: 'eze-root',
	template: `
		<router-outlet></router-outlet>
	`
})
export class AppComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit() {
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (typeof analytics !== 'undefined') {
					analytics.page();
				}
			});
	}
}

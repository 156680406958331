import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'eze-event-registration-container',
	templateUrl: './event-registration-container.component.html',
	styleUrls: ['./event-registration-container.component.css']
})
export class EventRegistrationContainerComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}

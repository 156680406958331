/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], [[8, "type", 0], [8, "disabled", 0], [8, "className", 0], [2, "no-style", null]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.disabled; var currVal_2 = i1.ɵinlineInterpolate(1, "btn btn-", _co.context, ""); var currVal_3 = _co.noStyle; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eze-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i2.ButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonComponentNgFactory = i1.ɵccf("eze-button", i2.ButtonComponent, View_ButtonComponent_Host_0, { type: "type", context: "context", noStyle: "noStyle", disabled: "disabled" }, {}, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };

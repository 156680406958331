import {
	Component,
	OnInit,
	ContentChildren,
	QueryList,
	AfterContentInit,
	Output,
	EventEmitter,
	Input,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
	selector: 'eze-tabset',
	templateUrl: './tabset.component.html',
	styleUrls: ['./tabset.component.css'],
})
export class TabsetComponent implements OnInit, AfterContentInit {
	@ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
	get tabComponents(): TabComponent[] {
		return this.tabs.toArray();
	}

	selectedTabIndex = 0;

	@Input() initTabIndex = 0;
	@Output() tabChange = new EventEmitter<number>();

	constructor() {}

	ngOnInit() {
		this.selectedTabIndex = this.initTabIndex || 0;
	}

	ngAfterContentInit() {
		// added this b/c we were getting the "changed after checked" error
		console.log({ tabComponents: this.tabComponents });
		setTimeout(() => {
			if (!this.tabComponents[this.selectedTabIndex]) {
				return;
			}
			this.tabComponents[this.selectedTabIndex].selected = true;
		}, 300);
	}

	pickTab(index: number) {
		if (!this.tabComponents[this.selectedTabIndex]) {
			return;
		}
		this.tabComponents[this.selectedTabIndex].selected = false;
		this.selectedTabIndex = index;
		this.tabComponents[this.selectedTabIndex].selected = true;
		this.tabChange.next(this.selectedTabIndex);
	}
}

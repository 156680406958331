import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'eze-tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.css'],
})
export class TabComponent implements OnInit {
	@Input() title = '';
	selected = false;

	constructor() {}

	ngOnInit() {}
}

import {
	Component,
	OnInit,
	ViewChild,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { generateFutureYears, months, getCreditCardMask } from '../constants';
import { AuthorizeNetService } from '../../core/services/authorizenet-service/authorizenet.service';
import { EventsService } from '../../core/services/events-service/events.service';
import { AlertComponent } from '../alert/alert.component';

@Component({
	selector: 'eze-authorize-net-payment',
	templateUrl: './authorize-net-payment.component.html',
	styleUrls: ['./authorize-net-payment.component.css'],
})
export class AuthorizeNetPaymentComponent implements OnInit {
	@ViewChild('modal', { static: false })
	modal;

	@ViewChild(AlertComponent, { static: false })
	alert: AlertComponent;

	@Input()
	price: number;

	@Input()
	payload: any; // TODO - competitor payload

	@Input()
	entity: string;

	@Input()
	eventId: string;

	@Output()
	success = new EventEmitter<any>();

	form: FormGroup;

	showValidation = false;

	years = [];

	submitting = false;

	get months() {
		return months;
	}

	constructor(
		private formBuilder: FormBuilder,
		private authroizeNetService: AuthorizeNetService,
		private eventsService: EventsService
	) {}

	ngOnInit() {
		this.initForm();
	}

	open() {
		this.modal.open();
	}

	onModalOpen() {
		this.alert.closeAlert();
		this.form.reset();
	}

	initForm() {
		this.form = this.formBuilder.group({
			fullName: ['', Validators.required],
			cardNumber: ['', Validators.required],
			month: ['', Validators.required],
			year: ['', Validators.required],
			cardCode: ['', Validators.required],
			zip: ['', Validators.required],
		});

		this.years = generateFutureYears();
	}

	onSubmit() {
		if (this.submitting) {
			return;
		}

		if (this.form.invalid) {
			this.alert.newAlert('Please fill out required fields', 'danger');
			this.showValidation = true;
			return;
		}

		let nonce = null;

		this.alert.newAlert('Submitting...', 'info');
		this.submitting = true;
		this.authroizeNetService
			.getAuthnetNonce(this.form.value)
			.pipe(
				mergeMap((nonceResponse) => {
					nonce = nonceResponse;
					return this.eventsService.postEntity(
						this.eventId,
						this.entity,
						this.payload
					);
				}),
				mergeMap((competitor: any) => {
					// TODO - not ANY!!!
					return this.authroizeNetService.executePayment(
						this.eventId,
						this.entity,
						competitor.id,
						nonce
					);
				}),
				tap(
					() => (this.submitting = false),
					() => (this.submitting = false)
				)
			)
			.subscribe(
				(res) => {
					this.success.next(res._html_message);
					this.modal.close();
				},
				(err: any[] | HttpErrorResponse) => {
					this.alert.newAlert(
						this.parseError(err) ||
							'There was an error in submitting your payment.',
						'danger'
					);
				}
			);
	}

	getCardMask(formControl) {
		return getCreditCardMask(this.form.get(formControl).value);
	}

	parseError(err: any[] | HttpErrorResponse): string {
		if (Array.isArray(err)) {
			return this.parseAcceptJsErrors(err);
		}

		return err && err.error && err.error.message;
	}

	parseAcceptJsErrors(errors: { code: string; text: string }[]): string {
		return errors
			.map((error) => {
				return error.text;
			})
			.join(' | ');
	}
}

import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	OnDestroy,
} from '@angular/core';

import { SessionService } from '../../core/services/session-service/session.service';
import { ProfileService } from '../../core/services/profile-service/profile.service';
import { BuildService } from '../../core/services/build.service';
import { EzModalComponent } from '../ez-modal';

@Component({
	selector: 'eze-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, OnDestroy {
	@Input() title = '';
	@Input() enableMenu = false;
	@Input() enableLogout = false;
	@Input() events = null;
	@Output() logoutSuccess = new EventEmitter();
	@Input() showHamburger = false;

	get isLoggedIn(): boolean {
		return this.sessionService.isLoggedIn;
	}

	showProfileDropdown = false;
	showEventDropdown = false;

	@ViewChild('refreshModal', { static: false })
	refreshModal: EzModalComponent;

	constructor(
		private sessionService: SessionService,
		private profileService: ProfileService,
		private buildService: BuildService
	) {}

	pollInterval = null;
	currentBuild = null;

	onWindowFocus = () => {
		this.getBuild();
	};

	ngOnInit() {
		window.addEventListener('focus', this.onWindowFocus);
		this.pollInterval = setInterval(() => {
			this.getBuild();
		}, 120000);
	}

	ngOnDestroy(): void {
		this.stopPolling();
		window.removeEventListener('focus', this.onWindowFocus);
	}

	stopPolling() {
		if (this.pollInterval) {
			clearInterval(this.pollInterval);
		}
	}

	getBuild() {
		this.buildService.getBuild().subscribe(
			({ build }) => {
				if (this.currentBuild && this.currentBuild !== build) {
					this.refreshModal.open();
					this.stopPolling();
					return;
				}
				this.currentBuild = build;
			},
			(err) => {}
		);
	}

	refreshPage() {
		location.reload();
	}

	clickHamburger() {
		if (document.body.className.indexOf('hidden-menu') !== -1) {
			document.body.className = document.body.className.replace(
				/hidden-menu\b/,
				''
			);
		} else {
			document.body.className += ' hidden-menu';
		}
	}

	closeProfileDropdownIfOpen() {
		if (this.showProfileDropdown) {
			this.showProfileDropdown = false;
		}
	}

	closeEventDropdownIfOpen() {
		if (this.showEventDropdown) {
			this.showEventDropdown = false;
		}
	}

	logout() {
		this.profileService
			.logoutUser()
			.subscribe(() => this.logoutSuccess.emit());
	}
}

import { FormGroup } from '@angular/forms';
import { _calculateAge } from '../utils/calculate-age.service';

export class EzValidators {
	static schoolRequired(control: FormGroup) {
		const value = control.value;
		if (value.id === '') {
			return { required: true };
		}

		if (value.id !== '-1') {
			return null;
		}

		const schoolFields = [
			'id',
			'name',
			'master',
			'address',
			'city',
			'state',
			'zip',
			'phone'
		];

		const output = {};

		schoolFields.forEach(field => {
			if (value[field] === '') {
				output[field] = { required: true };
			}
		});

		if (Object.keys(output).length > 0) {
			return output;
		} else {
			return null;
		}
	}

	static parentRequired(control: FormGroup) {
		if (!control || !EzValidators.isMinor(control)) {
			return null;
		}

		const requiredParentFields = [
			'parent_name_first',
			'parent_name_last',
			'parent_email',
			'parent_phone'
		];

		const output = {};
		const value = control.value;
		requiredParentFields.forEach(field => {
			if (value[field] === '') {
				output[field] = { required: true };
			}
		});

		if (Object.keys(output).length > 0) {
			return output;
		} else {
			return null;
		}
	}

	static isMinor(formGroup: FormGroup): boolean {
		const birth_date = formGroup.get('birth_date').value;
		return _calculateAge(new Date(birth_date)) < 18;
	}

	static passwordsMatch(control: FormGroup) {
		try {
			const password = control.get('password').value;
			const confirmPassword = control.get('confirmPassword').value;

			if (password !== confirmPassword) {
				return { passwordsDontMatch: {passwordsDontMatch: true} };
			} else {
				return null;
			}
		} catch (error) {
			throw new Error('Must use passwordsMatch validator with a form group that has the following fields: "password" and "confirmPassword"');
		}
	}
}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guards
import { EventResolverService } from './router-guards/event-resolver/event-resolver.service';
import { ProfileResolverService } from './router-guards/profile-resolver/profile-resolver.service';
import { IsAuthenticatedGuard } from './router-guards/isAuthenticated/is-authenticated.guard';
import { IsNotAuthenticatedGuard } from './router-guards/isNotAuthenticated/is-not-authenticated.guard';

// components
import { EventRegistrationContainerComponent } from './event-registration-container/event-registration-container.component';
import { AppContainerComponent } from './app-container/app-container.component';
import { ConsoleComponent } from './console/console.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
	{ path: '', redirectTo: '/ez', pathMatch: 'full' },
	{ path: 'live', redirectTo: '/events/jki30th/live' },
	{
		path: 'events/:eventId',
		loadChildren: 'app/billboard/billboard.module#BillboardModule',
	},
	// Event Registration
	{
		path: '',
		component: EventRegistrationContainerComponent,
		resolve: { profile: ProfileResolverService },
		children: [
			{
				path: 'events',
				loadChildren: 'app/events/events.module#EventsModule',
			},
		],
	},
	// EZ Console
	{
		path: 'ez/login',
		canActivate: [IsNotAuthenticatedGuard],
		loadChildren: 'app/ez-login/ez-login.module#LoginModule',
	},
	{
		path: 'ez',
		canActivate: [IsAuthenticatedGuard],
		component: AppContainerComponent,
		children: [
			{
				path: '',
				loadChildren: 'app/ez/me/me.module#MeModule',
			},
		],
	},
	// EZ Console - Event Administration
	{
		path: 'ez/events/:id',
		canActivate: [IsAuthenticatedGuard],
		resolve: {
			event: EventResolverService,
		},
		component: ConsoleComponent,
		children: [
			{
				path: '',
				redirectTo: 'dashboard',
				pathMatch: 'full',
			},
			{
				path: 'dashboard',
				loadChildren:
					'app/ez/public-dashboard/public-dashboard.module#PublicDashboardModule',
			},
			{
				path: 'competitors',
				loadChildren:
					'app/ez/competitors/ezcompetitors.module#EzCompetitorsModule',
			},
			{
				path: 'pending-competitors',
				loadChildren:
					'app/ez/pending-competitor/pending-competitor.module#PendingCompetitorModule',
			},
			{
				path: 'weigh-in',
				loadChildren: 'app/ez/weigh-in/weigh-in.module#WeighInModule',
			},
			{
				path: 'volunteers',
				loadChildren:
					'app/ez/volunteers/volunteers.module#VolunteersModule',
			},
			{
				path: 'masters',
				loadChildren: 'app/ez/masters/masters.module#MastersModule',
			},
			{
				path: 'coaches',
				loadChildren: 'app/ez/coaches/coaches.module#CoachesModule',
			},
			{
				path: 'referees',
				loadChildren: 'app/ez/referees/referees.module#RefereesModule',
			},
			{
				path: 'owners',
				loadChildren: 'app/ez/owners/owners.module#OwnersModule',
			},
			{
				path: 'api-docs',
				loadChildren: 'app/ez/api-docs/api-docs.module#ApiDocsModule',
			},
			{
				path: 'protests',
				loadChildren: 'app/ez/protests/protests.module#ProtestsModule',
			},
			{
				path: 'reports',
				loadChildren: 'app/ez/reports/reports.module#ReportsModule',
			},
		],
	},
	{
		path: 'ez2/events/:id',
		loadChildren: 'app/day-of/day-of.module#DayOfModule',
		resolve: { profile: ProfileResolverService },
	},
	{ path: '404', component: NotFoundComponent },
	{ path: '**', pathMatch: 'full', redirectTo: '/404' },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CoreRoutingModule {}

import {
	Component,
	OnInit,
	Input
} from '@angular/core';

import { NavigationTabs } from '..';

@Component({
	selector: 'eze-section-title',
	templateUrl: './section-title.component.html',
	styleUrls: ['./section-title.component.css']
})
export class SectionTitleComponent implements OnInit {
	@Input() currentNavTab: NavigationTabs;

	constructor() {}

	ngOnInit() {}

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EntityService } from '../entity-service';
import { Competitor } from './competitor.interface';

@Injectable()
export class NewCompetitorsService extends EntityService<Competitor> {
	url = '/api/v1/ez/events';
	entity = 'competitors';

	constructor(ezHttp: HttpClient) {
		super(ezHttp);
	}
}

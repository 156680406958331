import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	OnDestroy,
	Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'eze-day-of-search',
	templateUrl: './day-of-search.component.html',
	styleUrls: ['./day-of-search.component.css'],
})
export class DayOfSearchComponent implements OnInit, OnDestroy {
	formGroup: FormGroup;
	subscription: Subscription;
	@Output() search = new EventEmitter();
	@Output() clear = new EventEmitter();

	@Input()
	showId = true;

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit() {
		this.formGroup = this.formBuilder.group({
			name_first: '',
			name_last: '',
			id: '',
		});
		this.subscription = this.formGroup.valueChanges
			.pipe(debounceTime(500), distinctUntilChanged())
			.subscribe((value) => {
				this.search.emit(value);
			});
	}

	clearSearch() {
		this.formGroup.reset();
		this.clear.emit();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}

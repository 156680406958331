import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'eze-smart-form-container',
	templateUrl: './smart-form-container.component.html',
	styleUrls: ['./smart-form-container.component.css']
})
export class SmartFormContainerComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}

import { Component, OnInit, Input, ContentChild, AfterContentInit } from '@angular/core';
import { InputDirective } from './input.directive';

@Component({
	selector: 'eze-input-container',
	templateUrl: './input-container.component.html',
	styleUrls: ['./input-container.component.css']
})
export class InputContainerComponent implements OnInit, AfterContentInit {
	@Input() label = '';
	@ContentChild(InputDirective, {static: false}) inputElement: InputDirective;
	_required = false;
	@Input() set required (input: any) {
		if (input === '' || input === 'true' || input === true) {
			this._required = true;
		}
	}
	get required() {
		return this._required || this.inputElement.required;
	}

	constructor() { }

	ngOnInit() {
	}

	ngAfterContentInit() {
		this.autoSetLabel(this.inputElement.id);
	}

	autoSetLabel(str: string) {
		if (this.label === '') {
			this.label = str.charAt(0).toUpperCase() + str.slice(1).replace('_', ' ');
		}
	}

	get labelClass(): string {
		return this.inputElement.inputType.toLowerCase();
	}

	get showIconElement(): boolean {
		return this.isSelectElement || this.isCheckboxElement;
	}

	get isSelectElement(): boolean {
		return this.inputElement.inputType.toLowerCase() === 'select';
	}

	get isCheckboxElement(): boolean {
		return this.inputElement.inputType.toLowerCase() === 'checkbox';
	}

	get showError(): boolean {
		return this.inputElement.showError;
	}

	get errorMessages(): string [] {
		return this.inputElement.errors;
	}

	// TODO - handle this later
	// get errorMessage(): string {
	// 	return this.inputElement.errorMessage;
	// }

}

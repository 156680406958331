import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProfileService } from '../../services/profile-service/profile.service';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/profile-service/profile.service";
import * as i2 from "@angular/router";
var IsAuthenticatedGuard = /** @class */ (function () {
    function IsAuthenticatedGuard(profileService, router) {
        this.profileService = profileService;
        this.router = router;
    }
    IsAuthenticatedGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.profileService.getProfile().pipe(map(function () { return true; }), catchError(function (err) {
            _this.router.navigate(['ez', 'login']);
            return of(false);
        }));
    };
    IsAuthenticatedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsAuthenticatedGuard_Factory() { return new IsAuthenticatedGuard(i0.ɵɵinject(i1.ProfileService), i0.ɵɵinject(i2.Router)); }, token: IsAuthenticatedGuard, providedIn: "root" });
    return IsAuthenticatedGuard;
}());
export { IsAuthenticatedGuard };

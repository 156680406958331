import { Injectable } from '@angular/core';
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from '@angular/router';
import { Observable, throwError, of } from 'rxjs';
import { ProfileService } from '../../services/profile-service/profile.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {
	constructor(
		private profileService: ProfileService,
		private router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.profileService.getProfile().pipe(
			map(() => true),
			catchError(err => {
				this.router.navigate(['ez', 'login']);
				return of(false);
			})
		);
	}
}

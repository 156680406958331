import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, throwError, of } from 'rxjs';
import { ProfileService } from '../../services/profile-service/profile.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class IsNotAuthenticatedGuard implements CanActivate {
	constructor(
		private profileService: ProfileService,
		private router: Router
	) {}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this.profileService.getProfile().pipe(
			map(() => {
				this.router.navigate(['ez']);
				return false;
			}),
			catchError(() => {
				return of(true);
			})
		);
	}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal-content.component";
var styles_ModalContentComponent = [];
var RenderType_ModalContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalContentComponent, data: {} });
export { RenderType_ModalContentComponent as RenderType_ModalContentComponent };
export function View_ModalContentComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_ModalContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "eze-modal-content", [], null, null, null, View_ModalContentComponent_0, RenderType_ModalContentComponent)), i0.ɵdid(1, 114688, null, 0, i1.ModalContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalContentComponentNgFactory = i0.ɵccf("eze-modal-content", i1.ModalContentComponent, View_ModalContentComponent_Host_0, {}, {}, ["*"]);
export { ModalContentComponentNgFactory as ModalContentComponentNgFactory };

import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
	name: 'ezMomentDate'
})
export class MomentDatePipe implements PipeTransform {
	transform(value: any, args = 'MMMM Do, YYYY'): any {
		return moment(value).format(args);
	}
}

export const times = [
	{ value: '08:00', display: '8:00 AM' },
	{ value: '08:05', display: '8:05 AM' },
	{ value: '08:10', display: '8:10 AM' },
	{ value: '08:15', display: '8:15 AM' },
	{ value: '08:20', display: '8:20 AM' },
	{ value: '08:25', display: '8:25 AM' },
	{ value: '08:30', display: '8:30 AM' },
	{ value: '08:35', display: '8:35 AM' },
	{ value: '08:40', display: '8:40 AM' },
	{ value: '08:45', display: '8:45 AM' },
	{ value: '08:50', display: '8:50 AM' },
	{ value: '08:55', display: '8:55 AM' },
	{ value: '09:00', display: '9:00 AM' },
	{ value: '09:05', display: '9:05 AM' },
	{ value: '09:10', display: '9:10 AM' },
	{ value: '09:15', display: '9:15 AM' },
	{ value: '09:20', display: '9:20 AM' },
	{ value: '09:25', display: '9:25 AM' },
	{ value: '09:30', display: '9:30 AM' },
	{ value: '09:35', display: '9:35 AM' },
	{ value: '09:40', display: '9:40 AM' },
	{ value: '09:45', display: '9:45 AM' },
	{ value: '09:50', display: '9:50 AM' },
	{ value: '09:55', display: '9:55 AM' },
	{ value: '10:00', display: '10:00 AM' },
	{ value: '10:05', display: '10:05 AM' },
	{ value: '10:10', display: '10:10 AM' },
	{ value: '10:15', display: '10:15 AM' },
	{ value: '10:20', display: '10:20 AM' },
	{ value: '10:25', display: '10:25 AM' },
	{ value: '10:30', display: '10:30 AM' },
	{ value: '10:35', display: '10:35 AM' },
	{ value: '10:40', display: '10:40 AM' },
	{ value: '10:45', display: '10:45 AM' },
	{ value: '10:50', display: '10:50 AM' },
	{ value: '10:55', display: '10:55 AM' },
	{ value: '11:00', display: '11:00 AM' },
	{ value: '11:05', display: '11:05 AM' },
	{ value: '11:10', display: '11:10 AM' },
	{ value: '11:15', display: '11:15 AM' },
	{ value: '11:20', display: '11:20 AM' },
	{ value: '11:25', display: '11:25 AM' },
	{ value: '11:30', display: '11:30 AM' },
	{ value: '11:35', display: '11:35 AM' },
	{ value: '11:40', display: '11:40 AM' },
	{ value: '11:45', display: '11:45 AM' },
	{ value: '11:50', display: '11:50 AM' },
	{ value: '11:55', display: '11:55 AM' },
	{ value: '12:00', display: '12:00 PM' },
	{ value: '12:05', display: '12:05 PM' },
	{ value: '12:10', display: '12:10 PM' },
	{ value: '12:15', display: '12:15 PM' },
	{ value: '12:20', display: '12:20 PM' },
	{ value: '12:25', display: '12:25 PM' },
	{ value: '12:30', display: '12:30 PM' },
	{ value: '12:35', display: '12:35 PM' },
	{ value: '12:40', display: '12:40 PM' },
	{ value: '12:45', display: '12:45 PM' },
	{ value: '12:50', display: '12:50 PM' },
	{ value: '12:55', display: '12:55 PM' },
	{ value: '13:00', display: '1:00 PM' },
	{ value: '13:05', display: '1:05 PM' },
	{ value: '13:10', display: '1:10 PM' },
	{ value: '13:15', display: '1:15 PM' },
	{ value: '13:20', display: '1:20 PM' },
	{ value: '13:25', display: '1:25 PM' },
	{ value: '13:30', display: '1:30 PM' },
	{ value: '13:35', display: '1:35 PM' },
	{ value: '13:40', display: '1:40 PM' },
	{ value: '13:45', display: '1:45 PM' },
	{ value: '13:50', display: '1:50 PM' },
	{ value: '13:55', display: '1:55 PM' },
	{ value: '14:00', display: '2:00 PM' },
	{ value: '14:05', display: '2:05 PM' },
	{ value: '14:10', display: '2:10 PM' },
	{ value: '14:15', display: '2:15 PM' },
	{ value: '14:20', display: '2:20 PM' },
	{ value: '14:25', display: '2:25 PM' },
	{ value: '14:30', display: '2:30 PM' },
	{ value: '14:35', display: '2:35 PM' },
	{ value: '14:40', display: '2:40 PM' },
	{ value: '14:45', display: '2:45 PM' },
	{ value: '14:50', display: '2:50 PM' },
	{ value: '14:55', display: '2:55 PM' },
	{ value: '15:00', display: '3:00 PM' },
	{ value: '15:05', display: '3:05 PM' },
	{ value: '15:10', display: '3:10 PM' },
	{ value: '15:15', display: '3:15 PM' },
	{ value: '15:20', display: '3:20 PM' },
	{ value: '15:25', display: '3:25 PM' },
	{ value: '15:30', display: '3:30 PM' },
	{ value: '15:35', display: '3:35 PM' },
	{ value: '15:40', display: '3:40 PM' },
	{ value: '15:45', display: '3:45 PM' },
	{ value: '15:50', display: '3:50 PM' },
	{ value: '15:55', display: '3:55 PM' },
	{ value: '16:00', display: '4:00 PM' },
	{ value: '16:05', display: '4:05 PM' },
	{ value: '16:10', display: '4:10 PM' },
	{ value: '16:15', display: '4:15 PM' },
	{ value: '16:20', display: '4:20 PM' },
	{ value: '16:25', display: '4:25 PM' },
	{ value: '16:30', display: '4:30 PM' },
	{ value: '16:35', display: '4:35 PM' },
	{ value: '16:40', display: '4:40 PM' },
	{ value: '16:45', display: '4:45 PM' },
	{ value: '16:50', display: '4:50 PM' },
	{ value: '16:55', display: '4:55 PM' },
	{ value: '17:00', display: '5:00 PM' },
	{ value: '17:05', display: '5:05 PM' },
	{ value: '17:10', display: '5:10 PM' },
	{ value: '17:15', display: '5:15 PM' },
	{ value: '17:20', display: '5:20 PM' },
	{ value: '17:25', display: '5:25 PM' },
	{ value: '17:30', display: '5:30 PM' },
	{ value: '17:35', display: '5:35 PM' },
	{ value: '17:40', display: '5:40 PM' },
	{ value: '17:45', display: '5:45 PM' },
	{ value: '17:50', display: '5:50 PM' },
	{ value: '17:55', display: '5:55 PM' },
	{ value: '18:00', display: '6:00 PM' },
	{ value: '18:05', display: '6:05 PM' },
	{ value: '18:10', display: '6:10 PM' },
	{ value: '18:15', display: '6:15 PM' },
	{ value: '18:20', display: '6:20 PM' },
	{ value: '18:25', display: '6:25 PM' },
	{ value: '18:30', display: '6:30 PM' },
	{ value: '18:35', display: '6:35 PM' },
	{ value: '18:40', display: '6:40 PM' },
	{ value: '18:45', display: '6:45 PM' },
	{ value: '18:50', display: '6:50 PM' },
	{ value: '18:55', display: '6:55 PM' },
	{ value: '19:00', display: '7:00 PM' },
	{ value: '19:05', display: '7:05 PM' },
	{ value: '19:10', display: '7:10 PM' },
	{ value: '19:15', display: '7:15 PM' },
	{ value: '19:20', display: '7:20 PM' },
	{ value: '19:25', display: '7:25 PM' },
	{ value: '19:30', display: '7:30 PM' },
	{ value: '19:35', display: '7:35 PM' },
	{ value: '19:40', display: '7:40 PM' },
	{ value: '19:45', display: '7:45 PM' },
	{ value: '19:50', display: '7:50 PM' },
	{ value: '19:55', display: '7:55 PM' },
	{ value: '20:00', display: '8:00 PM' },
	{ value: '20:05', display: '8:05 PM' },
	{ value: '20:10', display: '8:10 PM' },
	{ value: '20:15', display: '8:15 PM' },
	{ value: '20:20', display: '8:20 PM' },
	{ value: '20:25', display: '8:25 PM' },
	{ value: '20:30', display: '8:30 PM' },
	{ value: '20:35', display: '8:35 PM' },
	{ value: '20:40', display: '8:40 PM' },
	{ value: '20:45', display: '8:45 PM' },
	{ value: '20:50', display: '8:50 PM' },
	{ value: '20:55', display: '8:55 PM' },
	{ value: '21:00', display: '9:00 PM' },
	{ value: '21:05', display: '9:05 PM' },
	{ value: '21:10', display: '9:10 PM' },
	{ value: '21:15', display: '9:15 PM' },
	{ value: '21:20', display: '9:20 PM' },
	{ value: '21:25', display: '9:25 PM' },
	{ value: '21:30', display: '9:30 PM' },
	{ value: '21:35', display: '9:35 PM' },
	{ value: '21:40', display: '9:40 PM' },
	{ value: '21:45', display: '9:45 PM' },
	{ value: '21:50', display: '9:50 PM' },
	{ value: '21:55', display: '9:55 PM' },
	{ value: '22:00', display: '10:00 PM' },
	{ value: '22:05', display: '10:05 PM' },
	{ value: '22:10', display: '10:10 PM' },
	{ value: '22:15', display: '10:15 PM' },
	{ value: '22:20', display: '10:20 PM' },
	{ value: '22:25', display: '10:25 PM' },
	{ value: '22:30', display: '10:30 PM' },
	{ value: '22:35', display: '10:35 PM' },
	{ value: '22:40', display: '10:40 PM' },
	{ value: '22:45', display: '10:45 PM' },
	{ value: '22:50', display: '10:50 PM' },
	{ value: '22:55', display: '10:55 PM' },
	{ value: '23:00', display: '11:00 PM' },
	{ value: '23:05', display: '11:05 PM' },
	{ value: '23:10', display: '11:10 PM' },
	{ value: '23:15', display: '11:15 PM' },
	{ value: '23:20', display: '11:20 PM' },
	{ value: '23:25', display: '11:25 PM' },
	{ value: '23:30', display: '11:30 PM' },
	{ value: '23:35', display: '11:35 PM' },
	{ value: '23:40', display: '11:40 PM' },
	{ value: '23:45', display: '11:45 PM' },
	{ value: '23:50', display: '11:50 PM' },
	{ value: '23:55', display: '11:55 PM' },
];

export const rings = [
	{
		value: '1',
		display: 'Ring 1',
	},
	{
		value: '2',
		display: 'Ring 2',
	},
	{
		value: '3',
		display: 'Ring 3',
	},
	{
		value: '4',
		display: 'Ring 4',
	},
	{
		value: '5',
		display: 'Ring 5',
	},
	{
		value: '6',
		display: 'Ring 6',
	},
	{
		value: '7',
		display: 'Ring 7',
	},
	{
		value: '8',
		display: 'Ring 8',
	},
	{
		value: '9',
		display: 'Ring 9',
	},
	{
		value: '10',
		display: 'Ring 10',
	},
];

import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
} from '@angular/core';

import { Profile } from '../../core/interfaces/profile.interface';
import { Event, School } from '../../events/interfaces';
import { Competitor } from '../../core/services/competitors-service/competitor.interface';
import { EventsService } from '../../core/services/events-service/events.service';
import { Observable } from 'rxjs';

type CompetitorSortFields = 'name_first' | 'name_last';
interface ProfileRegistrationDivision {
	id: number;
	name: string;
	description: string;
	registrations: string;
	date: string;
	time?: string; // todo - will come back soon
	weigh_in: {
		completed: boolean;
		rebracket: boolean;
		required: boolean;
	};
}
import { times } from '../times';
@Component({
	selector: 'eze-my-registration-cards',
	templateUrl: './my-registration-cards.component.html',
	styleUrls: ['./my-registration-cards.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyRegistrationCardsComponent implements OnInit {
	@Input()
	profile: Profile;

	@Input()
	event: Event;

	get eventCode(): string {
		return this.event.code;
	}

	get showMyRegistrations(): boolean {
		return (
			this.profile &&
			this.event &&
			!!this.profile.registrations[this.eventCode]
		);
	}

	get schoolRegistrations(): { name: string; Competitors: Competitor[] }[] {
		const registrations = this.profile.registrations[this.eventCode];
		const schoolMap = (registrations && registrations.schools) || {};
		const schools: { name: string; Competitors: Competitor[] }[] = [];
		for (let schoolKey in schoolMap) {
			const a = schoolMap[schoolKey];
			schools.push(a);
		}
		return schools;
	}

	constructor(private eventService: EventsService) {}

	ngOnInit() {}

	getDivisions(competitor: Competitor) {
		return Object.keys(competitor.divisions).map(
			(key) => competitor.divisions[key]
		);
	}

	divisionsObjToArray(divisionObj: object): ProfileRegistrationDivision[] {
		const output = [];
		for (let key in divisionObj) {
			output.push(divisionObj[key]);
		}

		return output;
	}

	competitorSortKey: CompetitorSortFields = 'name_first';
	competitorSortDirection: 'asc' | 'desc' = 'asc';

	sortCompetitorsBy(field: CompetitorSortFields) {
		if (this.competitorSortKey !== field) {
			this.competitorSortKey = field;
			this.competitorSortDirection = 'asc';
		} else if (
			this.competitorSortKey === field &&
			this.competitorSortDirection === 'asc'
		) {
			this.competitorSortDirection = 'desc';
		} else {
			this.competitorSortDirection = 'asc';
		}
	}

	sortCompetitors(competitors: Competitor[]): Competitor[] {
		return competitors.sort((a, b) => {
			if (this.competitorSortKey === null) {
				return 1;
			}

			if (this.competitorSortDirection === 'asc') {
				return a[this.competitorSortKey] > b[this.competitorSortKey]
					? 1
					: -1;
			}

			return a[this.competitorSortKey] < b[this.competitorSortKey]
				? 1
				: -1;
		});
	}

	getTimeCache = {};
	getTime(timeString: string): string {
		try {
			const cachedTime = this.getTimeCache[timeString];
			if (cachedTime) {
				return cachedTime;
			}

			const output = times.find((t) => t.value === timeString);
			this.getTimeCache[timeString] = output.display;
			return output.display;
		} catch (error) {
			return timeString;
		}
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event, School } from '../../../events/interfaces';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class EventsService {
	eventData: Event | null = null;
	schools: School[];

	waiver: { text: string; header: string } = {
		text: '',
		header: '',
	};

	constructor(private http: HttpClient) {}

	// refactor
	getEventData(eventId: string) {
		this.getSchools(eventId).subscribe();

		return this.getEvent(eventId).pipe(
			map((res: Event) => {
				const output: Event = { ...res };

				const ranks = [];

				const value = output.ranks;
				for (const key in value) {
					if (value[key]) {
						ranks.push({ value: key, desc: value[key] });
					}
				}

				output.ranks = ranks
					.map((rank) => {
						rank.value = Number(rank.value);
						return rank;
					})
					.sort((a, b) => {
						if (a.value > b.value) {
							return 1;
						}
						if (a.value < b.value) {
							return -1;
						}
						// a must be equal to b
						return 0;
					});

				const masterRanks = [];
				for (const key in output.masters.ranks) {
					if (output.masters.ranks[key]) {
						masterRanks.push({
							value: key,
							desc: output.masters.ranks[key],
						});
					}
				}

				output.masters.ranks = masterRanks
					.map((rank) => {
						rank.value = Number(rank.value);
						return rank;
					})
					.sort((a, b) => {
						if (a.value > b.value) {
							return 1;
						}
						if (a.value < b.value) {
							return -1;
						}
						// a must be equal to b
						return 0;
					});

				return output;
			}),
			tap((res: Event) => {
				this.eventData = res;
			})
		);
	}

	// refactor
	getEvent(eventId: string): Observable<Event> {
		return this.http.get(`/api/v1/events/${eventId}`) as Observable<Event>;
	}

	getWaiverText(url) {
		return this.http.get(url, { responseType: 'text' });
	}

	getSchools(eventId) {
		return this.http
			.get(`/api/v1/events/${eventId}/schools`)
			.pipe(tap((res: School[]) => (this.schools = res)));
	}

	getSchoolDetails(eventId: string, schoolId: string) {
		return this.http.get(`/api/v1/events/${eventId}/schools/${schoolId}`);
	}

	postVolunteer(eventId: string, volunteer: any) {
		return this.http
			.post(`/api/v1/events/${eventId}/volunteers`, volunteer)
			.pipe(tap(() => this.getSchools(eventId).subscribe()));
	}

	postVIP(eventId: string, vip: any) {
		return this.http
			.post(`/api/v1/events/${eventId}/vips`, vip)
			.pipe(tap(() => this.getSchools(eventId).subscribe()));
	}

	postReferee(eventId: string, referee: any) {
		const payload: any = { ...referee };

		// payload.hotel = payload.hotel ? 'Y' : 'N';

		return this.http
			.post(`/api/v1/events/${eventId}/referees`, payload)
			.pipe(tap(() => this.getSchools(eventId).subscribe()));
	}

	postCompetitor(eventId: string, competitor: any) {
		return this.http
			.post(`/api/v1/events/${eventId}/competitors`, competitor)
			.pipe(tap(() => this.getSchools(eventId).subscribe()));
	}

	postEntity(eventId: string, entity: string, payload: any) {
		return this.http
			.post(`/api/v1/events/${eventId}/${entity}`, payload)
			.pipe(tap(() => this.getSchools(eventId).subscribe()));
	}

	getDivisionData(
		eventId: string,
		rank: string,
		gender: string,
		birth_date: string,
		weight: string
	) {
		const genderValue =
			gender.toLocaleLowerCase() === 'male' ||
			gender.toLocaleLowerCase() === 'm'
				? 'M'
				: 'F';
		const queryString = `rank=${rank}&gender=${genderValue}&birth_date=${birth_date}&weight=${weight}`;
		return this.http.get(
			`/api/v1/events/${eventId}/divisions?${queryString}`
		);
	}

	getPrice(eventId: string, divisionIds: number[], schoolId?: string) {
		if (!divisionIds || divisionIds.length === 0) {
			return of(0);
		} else {
			const queryString = encodeURI(
				divisionIds
					.map((d, i) => {
						return `divisions[${i}]=${d}`;
					})
					.join('&')
			);
			let url = `/api/v1/events/${eventId}/price?${queryString}`;
			if (schoolId) {
				url += `&schoolId=${schoolId}`;
			}
			return this.http.get(url);
		}
	}

	getPriceAnyParams(eventId: string, params: any) {
		return this.http
			.get(`/api/v1/events/${eventId}/price`, { params: params })
			.pipe(map((res: { price: string }) => res.price));
	}

	get ranks(): { value: number; desc: string }[] {
		return this.eventData.ranks;
	}

	isRegistrationClosed(formName: string): boolean {
		const deadline = this.eventData.deadlines[formName];

		return deadline === 'closed';
	}

	getDeadline(formName: string): Date {
		return new Date(this.eventData.deadlines[formName]);
	}

	postRegistrationImage(eventId, formData: FormData) {
		return this.http.post(`/api/v1/events/${eventId}/images`, formData);
	}

	postRegistrationDocument(eventId, formData: FormData) {
		return this.http.post(`/api/v1/events/${eventId}/documents`, formData);
	}

	paymentCodeExecute(eventId, entity, entityId, payment_code) {
		return this.http.post(
			`/api/v1/events/${eventId}/${entity}/${entityId}/paymentcode-execute`,
			{
				payment_code: payment_code,
			}
		);
	}

	getEventRankFromRankNumber(rankNumber: Number): {
		value: string;
		desc: string;
	} {
		return this.eventData.ranks.find((rank) => rank.value === rankNumber);
	}

	getApiDocs(eventId: string): Observable<{ html: string }> {
		return this.http.get<{ html: string }>(
			`/api/v1/ez/events/${eventId}/api_docs`
		);
	}
}

// services
import { rollbarFactory } from './services/rollbar-error-handler/rollbar-error-handler.service';
var ɵ0 = rollbarFactory;
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0 };

export const days = [];

for (let i = 1; i < 32; i++) {
	days.push(pad(i, 2));
}

function pad(num, size) {
	let s = num + '';
	while (s.length < size) {
		s = '0' + s;
	}
	return s;
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./console.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../../shared/navbar/navbar.component";
import * as i4 from "../services/session-service/session.service";
import * as i5 from "../services/profile-service/profile.service";
import * as i6 from "../services/build.service";
import * as i7 from "../../shared/side-menu/side-menu.component.ngfactory";
import * as i8 from "../../shared/side-menu/side-menu.component";
import * as i9 from "../../shared/section-title/section-title.component.ngfactory";
import * as i10 from "../../shared/section-title/section-title.component";
import * as i11 from "@angular/router";
import * as i12 from "./console.component";
import * as i13 from "../services/events-service/events.service";
var styles_ConsoleComponent = [i0.styles];
var RenderType_ConsoleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsoleComponent, data: {} });
export { RenderType_ConsoleComponent as RenderType_ConsoleComponent };
export function View_ConsoleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eze-navbar", [], null, [[null, "logoutSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logoutSuccess" === en)) {
        var pd_0 = (_co.goToLoginPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 245760, null, 0, i3.NavbarComponent, [i4.SessionService, i5.ProfileService, i6.BuildService], { title: [0, "title"], enableMenu: [1, "enableMenu"], enableLogout: [2, "enableLogout"], events: [3, "events"], showHamburger: [4, "showHamburger"] }, { logoutSuccess: "logoutSuccess" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "eze-side-menu", [], null, null, null, i7.View_SideMenuComponent_0, i7.RenderType_SideMenuComponent)), i1.ɵdid(3, 114688, null, 0, i8.SideMenuComponent, [i1.NgZone], { navTabs: [0, "navTabs"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["id", "console-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "eze-section-title", [], null, null, null, i9.View_SectionTitleComponent_0, i9.RenderType_SectionTitleComponent)), i1.ɵdid(6, 114688, null, 0, i10.SectionTitleComponent, [], { currentNavTab: [0, "currentNavTab"] }, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i11.RouterOutlet, [i11.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.name; var currVal_1 = true; var currVal_2 = true; var currVal_3 = ((_co.profile == null) ? null : _co.profile.events); var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.navigationTabs; _ck(_v, 3, 0, currVal_5); var currVal_6 = _co.currentRoute; _ck(_v, 6, 0, currVal_6); _ck(_v, 8, 0); }, null); }
export function View_ConsoleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eze-console", [], null, null, null, View_ConsoleComponent_0, RenderType_ConsoleComponent)), i1.ɵdid(1, 114688, null, 0, i12.ConsoleComponent, [i11.ActivatedRoute, i11.Router, i5.ProfileService, i13.EventsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsoleComponentNgFactory = i1.ɵccf("eze-console", i12.ConsoleComponent, View_ConsoleComponent_Host_0, {}, {}, []);
export { ConsoleComponentNgFactory as ConsoleComponentNgFactory };

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// modules
import { CoreModule } from './core/core.module';

// components
import { AppComponent } from './app.component';

// use core module for main routes
const routes: Routes = [];

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		RouterModule.forRoot(routes, { useHash: false }),
		CoreModule,
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}

<eze-navbar
	[title]="event.name"
	[enableLogout]="true"
	[enableMenu]="true"
	(logoutSuccess)="goToLoginPage()"
	[events]="profile?.events"
	[showHamburger]="true"
></eze-navbar>

<eze-side-menu [navTabs]="navigationTabs"></eze-side-menu>

<div id="console-content">
	<eze-section-title [currentNavTab]="currentRoute"></eze-section-title>

	<router-outlet></router-outlet>
</div>

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CompetitorListResponse } from './CompetitorListResponse.interface'
import { Competitor } from './competitor.interface';


@Injectable()
export class CompetitorsService {
	url = '/api/v1/ez/events';

	constructor(private ezHttp: HttpClient) {}

	getCompetitorList(eventId: string, limit = '25', offset = '0') : Observable<CompetitorListResponse> {
		return this.ezHttp.get<CompetitorListResponse>(
			`${this.url}/${eventId}/competitors?limit=${limit}&offset=${offset}`
		);
	}

	getCompetitorDetails(eventId: string, competitorId: string): Observable<Competitor> {
		return this.ezHttp
			.get(`${this.url}/${eventId}/competitors/${competitorId}`)
			.pipe(
				map((res: any) => {
					res.divisions = Object.keys(res.divisions).map(
						key => res.divisions[key]
					);
					return res;
				})
			);
	}

	updateCompetitor(eventId: string, competitorId: string, payload) {
		return this.ezHttp.put(
			`${this.url}/${eventId}/competitors/${competitorId}`,
			payload
		);
	}

	removeEventEntity(eventId: string, competitorId: string) {
		return this.ezHttp.delete(
			`${this.url}/${eventId}/competitors/${competitorId}`
		);
	}

	resendConfirmationEmail(eventId: string, competitorId: string) {
		return this.ezHttp.post(
			`${this.url}/${eventId}/competitors/${competitorId}/confirmation`,
			null
		);
	}
}

<header id="header">
	<div id="logo-group">
		<span id="logo">
			<a href="http://ez-events.info/" target="_blank">
				<img src="/assets/img/logo.png" alt="EZ-Events" align="left" />
			</a>
		</span>
	</div>

	<div class="title-dropdown" *ngIf="enableMenu">
		<div
			(click)="showEventDropdown = !showEventDropdown"
			(ezeClickOutside)="closeEventDropdownIfOpen()"
		>
			<div class="label">Event:</div>
			<div class="event-name">
				<strong>{{ title }}</strong>
			</div>
		</div>

		<ul
			class="dropdown-menu pointer"
			[class.show]="showEventDropdown"
			aria-labelledby="eventDropdown"
			*ngIf="events"
		>
			<li>
				<a [routerLink]="['/ez']"> Home </a>
			</li>
			<li *ngFor="let event of events">
				<a [routerLink]="['/ez', 'events', event.code]">{{
					event.name
				}}</a>
			</li>
		</ul>
	</div>

	<div class="btn-header pull-right" *ngIf="showHamburger">
		<span class="hamburger">
			<a
				title="Menu Toggle"
				style="cursor: pointer !important"
				(click)="clickHamburger()"
			>
				<i class="fa fa-bars"></i>
			</a>
		</span>
	</div>

	<div class="btn-header pull-right">
		<span>
			<a class="home-link" routerLink="/ez">
				<span
					class="visible-md-inline visible-lg-inline visible-xl-inline"
				>
					Home
				</span>
				<i class="fa fa-home"></i> </a
		></span>
	</div>

	<div
		class="dropdown pull-right"
		*ngIf="enableLogout && isLoggedIn"
		(click)="showProfileDropdown = !showProfileDropdown"
		(ezeClickOutside)="closeProfileDropdownIfOpen()"
	>
		<div class="btn-header pull-right">
			<span>
				<a title="Collapse Menu" style="cursor: pointer !important">
					<i class="fa fa-user"></i>
				</a>
			</span>
		</div>
		<ul
			class="dropdown-menu pointer"
			[class.show]="showProfileDropdown"
			aria-labelledby="profileDropdown"
		>
			<li><a [routerLink]="['/ez']">My Events</a></li>
			<!-- <li><a [routerLink]="['/ez/profile']">My Profile</a></li> -->
			<li><a (click)="logoutModal.open()">Logout</a></li>
		</ul>
	</div>
</header>

<eze-modal #logoutModal>
	<eze-modal-header>
		<h1>Warning</h1>
	</eze-modal-header>
	<eze-modal-content>
		<h3>Are you sure you want to logout?</h3>
	</eze-modal-content>
	<eze-modal-footer>
		<eze-button
			context="default"
			(click)="logoutModal.close()"
			style="margin-right: 10px"
			>Cancel</eze-button
		>
		<eze-button context="danger" (click)="logout(); logoutModal.close()"
			>Yes, log me out.</eze-button
		>
	</eze-modal-footer>
</eze-modal>

<eze-modal #refreshModal [canDismiss]="false">
	<eze-modal-header>
		<h1>Application Update</h1>
	</eze-modal-header>
	<eze-modal-content>
		<h3>
			There's an update to the application. Please refresh the page to
			continue.
		</h3>
	</eze-modal-content>
	<eze-modal-footer>
		<eze-button context="primary" (click)="refreshPage()">
			Okay
		</eze-button>
	</eze-modal-footer>
</eze-modal>

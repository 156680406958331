import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BuildService = /** @class */ (function () {
    function BuildService(http) {
        this.http = http;
        this.currentBuild = null;
    }
    BuildService.prototype.getBuild = function () {
        return this.http.get('/api/v1/build');
    };
    BuildService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BuildService_Factory() { return new BuildService(i0.ɵɵinject(i1.HttpClient)); }, token: BuildService, providedIn: "root" });
    return BuildService;
}());
export { BuildService };

<aside id="left-panel" class="print1">
	<nav>
		<ul (click)="closeNavbarOnMobile()">
			<ng-template ngFor let-tab [ngForOf]="navTabs" let-i="index">
				<!-- nav tab without nested tabs -->
				<!-- NO external URL -->
				<li *ngIf="!tab.children && !tab.externalRoute" class="pointer" [routerLink]="tab.route" routerLinkActive="active">
					<a title="{{tab.label}}">
							<span class="{{tab.icon}}" [ngStyle]="{width: tab.width ? tab.width : '2.1em', 'font-size': tab.fontSize ? tab.fontSize : '1em'}"></span>
							<span class="menu-item-parent">{{tab.label}}</span>
						</a>
				</li>
				<!-- external URL -->
				<li *ngIf="!tab.children && tab.externalRoute">
					<a title="{{tab.label}}" [href]="tab.externalRoute">
							<span class="{{tab.icon}}" [ngStyle]="{width: tab.width ? tab.width : '2.1em', 'font-size': tab.fontSize ? tab.fontSize : '1em'}"></span>
							<span class="menu-item-parent">{{tab.label}}</span>
						</a>
				</li>
				<!-- nav tab with nested tabs -->
				<li *ngIf="tab.children" (click)="click(i)" class="pointer">
					<a>
							<span class="{{tab.icon}}" [ngStyle]="{width: tab.width ? tab.width : '2.1em', 'font-size': tab.fontSize ? tab.fontSize : '1em'}"></span>
							<span class="menu-item-parent">{{tab.label}}</span>
							<b class="collapse-sign">
								<em *ngIf="open !== i" class="fa fa-plus-square-o"></em>
								<em *ngIf="open === i" class="fa fa-minus-square-o"></em>
							</b>
						</a>
					<ul style="display: block;" [@slideState]="findState(i)" (click)="$event.stopPropagation()">
						<ng-template ngFor let-child [ngForOf]="tab.children">
							<!-- NO external URL -->
							<li *ngIf="!child.externalRoute" [routerLink]="child.route" routerLinkActive="active">
								<a>{{child.label}}</a>
							</li>
							<!-- external URL -->
							<li *ngIf="child.externalRoute">
								<a [href]="child.externalRoute">{{child.label}}</a>
							</li>
						</ng-template>
					</ul>
				</li>
			</ng-template>
		</ul>
	</nav>
	<span class="minifyme" (click)="minify()"> <i class="fa fa-arrow-circle-left hit txt-color-white"></i> </span>

</aside>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'eze-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit() {
		if (environment.production) {
			window.location.href = 'http://ez-events.info/';
		} else {
			this.router.navigate(['/ez']);
		}
	}
}

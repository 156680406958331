export const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const phoneMasks = [
	{
		pregs: [/^\(1/, /^1 \(/],
		mask: [/[1-9]/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
	},
];

export function getPhoneMask(input: string | number) {
	let phoneNumber: string;
	if (typeof input === 'number') {
		phoneNumber = input.toString();
	} else {
		phoneNumber = input;
	}

	// default
	let mask: (string | RegExp)[] = phoneMask;

	phoneMasks.forEach(maskConfig => {
		maskConfig.pregs.forEach(preg => {
			if (preg.test(phoneNumber)) {
				mask = maskConfig.mask;
			}
		});
	});

	return mask;
}


export const ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];




const creditCardMasks = [
	{
		brand: 'American Express',
		pregs: [/^3(4|7)/],
		mask: [
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/, /\d/
		],
	},
	{
		brand: 'Visa',
		pregs: [/^4/],
		mask: [
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/,
		],
	},
	{
		brand: 'MasterCard',
		pregs: [/^5(1|2|5)/, /^22/],
		mask: [
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/,
		],
	},
	{
		brand: 'Diners Club',
		pregs: [/^30(1|2|3|4|5)/, /^36/],
		mask: [
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/, /\d/, /\d/, '-',
			/\d/, /\d/
		],
	},
];

export function getCreditCardMask(input: string | number) {
	let cardnumber: string;
	if (typeof input === 'number') {
		cardnumber = input.toString();
	} else {
		cardnumber = input;
	}

	// default
	let mask: (string | RegExp)[] = [
		/\d/, /\d/, /\d/, /\d/,
		/\d/, /\d/, /\d/, /\d/,
		/\d/, /\d/, /\d/, /\d/,
		/\d/, /\d/, /\d/, /\d/
	];

	creditCardMasks.forEach(cardMask => {
		cardMask.pregs.forEach(preg => {
			if (preg.test(cardnumber)) {
				mask = cardMask.mask;
			}
		});
	});

	return mask;
}
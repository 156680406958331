import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NavigationTabs } from '../../shared';
import { Event } from '../../events/interfaces';
import { ProfileService } from '../services/profile-service/profile.service';
import { combineLatest } from 'rxjs';
import { EventsService } from '../services/events-service/events.service';

@Component({
	selector: 'eze-console',
	templateUrl: './console.component.html',
	styleUrls: ['./console.component.css'],
})
export class ConsoleComponent implements OnInit {
	navigationTabs: NavigationTabs[] = [];
	profile = null;

	event: Event;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private profileService: ProfileService,
		private eventService: EventsService
	) {}

	ngOnInit() {
		this.profileService.getProfile().subscribe((profile) => {
			this.profile = profile;
		});
		this.route.data.subscribe((data: { event: Event }) => {
			this.event = data.event;
		});
		combineLatest(
			this.route.data,
			this.profileService.eventPermissions
		).subscribe((data: [{ event: Event }, any]) => {
			this.buildNavigationTabs(data);
		});
	}

	get currentRoute(): NavigationTabs {
		const currentUrl = this.route.children[0].snapshot.url[0].path;
		return this.navigationTabs.filter(
			(tab) => tab.route[0] === currentUrl
		)[0];
	}

	goToLoginPage() {
		this.router.navigate(['/ez/login']);
	}

	buildNavigationTabs([{ event }]: [{ event: Event }, {} | any]) {
		const tabs = [
			{
				label: 'Dashboard',
				icon: 'fa fa-fw fa-tachometer',
				route: ['dashboard'],
			},
		];

		if (
			this.profileService.hasPermission(event.code, 'competitor', [
				'view',
			])
		) {
			tabs.push({
				label: 'Competitors',
				icon: 'fa fa-fw fa-users',
				route: ['competitors'],
			});
		}
		if (
			this.profileService.hasPermission(event.code, 'competitor', [
				'weighin',
			])
		) {
			tabs.push({
				label: 'Competitor Weigh In',
				icon: 'fa fa-fw fa-balance-scale',
				route: ['weigh-in'],
			});
		}
		if (
			this.profileService.hasPermission(event.code, 'volunteer', ['view'])
		) {
			tabs.push({
				label: 'Volunteers',
				icon: 'fa fa-fw fa-hand-paper-o',
				route: ['volunteers'],
			});
		}
		if (this.profileService.hasPermission(event.code, 'master', ['view'])) {
			tabs.push({
				label: 'Masters',
				icon: 'fa fa-fw fa-users',
				route: ['masters'],
			});
		}
		if (this.profileService.hasPermission(event.code, 'coach', ['view'])) {
			tabs.push({
				label: 'Coaches',
				icon: 'fa fa-fw fa-users',
				route: ['coaches'],
			});
		}
		if (
			this.profileService.hasPermission(event.code, 'referee', ['view'])
		) {
			tabs.push({
				label: 'Referees',
				icon: 'fa fa-fw fa-users',
				route: ['referees'],
			});
		}
		if (this.profileService.hasPermission(event.code, 'owner', ['view'])) {
			tabs.push({
				label: 'Owners',
				icon: 'fa fa-fw fa-users',
				route: ['owners'],
			});
		}
		if (
			this.profileService.hasPermission(event.code, 'api_docs', ['view'])
		) {
			tabs.push({
				label: 'API Docs',
				icon: 'fa fa-fw fa-file-text-o',
				route: ['api-docs'],
			});
		}
		if (this.profileService.hasPermission(event.code, 'coach', ['view'])) {
			tabs.push({
				label: 'Protests',
				icon: 'fa fa-fw fa-file-text-o',
				route: ['protests'],
			});
		}
		if (
			this.profileService.hasPermission(event.code, 'reports', ['view'])
		) {
			tabs.push({
				label: 'Reports',
				icon: 'fa fa-fw fa-file-o',
				route: ['reports'],
			});
		}
		this.navigationTabs = tabs;
	}
}

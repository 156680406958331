import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsService } from '../../../core/services/events-service/events.service';

import { stateList, getPhoneMask } from '../../constants';

@Component({
	selector: 'eze-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() showValidation: boolean = true;
	@Input() isStandardEvent = true;

	constructor(private eventService: EventsService) {}

	ngOnInit() {}

	get stateList(): string[] {
		return stateList;
	}

	getPhoneMask(formControl) {
		return getPhoneMask(this.formGroup.get(formControl).value);
	}
}

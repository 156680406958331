import {
	Component,
	OnInit,
	ViewChild,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { includes } from '../utils/includes';
import { generateFutureYears, months, getCreditCardMask } from '../constants';
import { EventsService } from '../../core/services/events-service/events.service';
import { AlertComponent } from '../alert/alert.component';

declare const Stripe: any;

@Component({
	selector: 'eze-stripe-payment-modal',
	templateUrl: './stripe-payment-modal.component.html',
	styleUrls: ['./stripe-payment-modal.component.css'],
})
export class StripePaymentModalComponent implements OnInit {
	@ViewChild('modal', { static: false })
	modal;

	@ViewChild(AlertComponent, { static: false })
	alert: AlertComponent;

	@Input()
	price: number;

	@Input()
	payload: any;

	@Input()
	entity: string;

	@Input()
	eventId: string;

	@Output()
	success = new EventEmitter<any>();

	form: FormGroup;

	showValidation = false;

	years = [];

	submitting = false;

	@Input()
	stripeId: string = null;

	stripe = null;
	card = null;
	isCardComplete = false;
	cardError = null;

	stripeCountries = [
		{ country: 'Australia', code: 'AU' },
		{ country: 'Austria', code: 'AT' },
		{ country: 'Belgium', code: 'BE' },
		{ country: 'Bulgaria', code: 'BG' },
		{ country: 'Brazil ', code: 'BR' },
		{ country: 'Canada', code: 'CA' },
		{ country: 'Cyprus', code: 'CY' },
		{ country: 'Czech Republic', code: 'CZ' },
		{ country: 'Denmark', code: 'DK' },
		{ country: 'Estonia', code: 'EE' },
		{ country: 'Finland', code: 'FI' },
		{ country: 'France', code: 'FR' },
		{ country: 'Germany', code: 'DE' },
		{ country: 'Greece', code: 'GR' },
		{ country: 'Hong Kong', code: 'HK' },
		{ country: 'India', code: 'IN' },
		{ country: 'Ireland', code: 'IE' },
		{ country: 'Italy', code: 'IT' },
		{ country: 'Japan', code: 'JP' },
		{ country: 'Latvia', code: 'LV' },
		{ country: 'Lithuania', code: 'LT' },
		{ country: 'Luxembourg', code: 'LU' },
		{ country: 'Malaysia', code: 'MY' },
		{ country: 'Malta', code: 'MT' },
		{ country: 'Mexico ', code: 'MX' },
		{ country: 'Netherlands', code: 'NL' },
		{ country: 'New Zealand', code: 'NZ' },
		{ country: 'Norway', code: 'NO' },
		{ country: 'Poland', code: 'PL' },
		{ country: 'Portugal', code: 'PT' },
		{ country: 'Romania', code: 'RO' },
		{ country: 'Singapore', code: 'SG' },
		{ country: 'Slovakia', code: 'SK' },
		{ country: 'Slovenia', code: 'SI' },
		{ country: 'Spain', code: 'ES' },
		{ country: 'Sweden', code: 'SE' },
		{ country: 'Switzerland', code: 'CH' },
		{ country: 'United Kingdom', code: 'GB' },
		{ country: 'United States', code: 'US' },
	];

	get months() {
		return months;
	}

	constructor(
		private formBuilder: FormBuilder,
		private eventsService: EventsService
	) {}

	ngOnInit() {
		this.stripe = Stripe(this.stripeId);
		this.initForm();
		this.createStripeCardElement();
	}

	open() {
		this.modal.open();
	}

	onModalOpen() {
		this.alert.closeAlert();
		this.form.reset();
	}

	initForm() {
		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			address: [''],
			city: [''],
			state: [''],
			zip: ['', Validators.required],
			country: ['US'],
		});

		this.years = generateFutureYears();
	}

	showStateField(): boolean {
		return includes(['AU', 'US'], this.form.get('country').value);
	}

	zipLabel() {
		switch (this.form.get('country').value) {
			case 'US':
				return 'ZIP';
			case 'GB':
				return 'Postcode';
			case 'AU':
				return 'Postcode';
			default:
				return 'Postal Code';
		}
	}

	createStripeCardElement() {
		const elements = this.stripe.elements();
		const style = {
			base: {
				iconColor: '#666ee8',
				color: '#31325f',
				fontWeight: 400,
				fontFamily:
					'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '15px',
				'::placeholder': {
					color: '#aab7c4',
				},
				':-webkit-autofill': {
					color: '#666ee8',
				},
			},
		};
		this.card = elements.create('card', { style, hidePostalCode: true });
		this.card.mount('#card-element');
		this.card.on('change', ({ complete, error }) => {
			this.isCardComplete = complete;
			this.cardError = error;
		});
	}

	onSubmit() {
		if (this.submitting) {
			return;
		}

		if (this.form.invalid) {
			this.alert.newAlert('Please fill out required fields', 'danger');
			this.showValidation = true;
			return;
		}

		if (!this.isCardComplete) {
			this.alert.newAlert('Please fill out card information', 'danger');
			this.showValidation = true;
			this.cardError = { message: 'Please fill out card information' };
			return;
		}

		this.alert.newAlert('Submitting...', 'info');
		this.submitting = true;

		this.eventsService
			.postEntity(this.eventId, this.entity, {
				...this.payload,
				processor: 'stripe',
			})
			.subscribe(
				(res: { payment: { id: string } }) => {
					this.stripe
						.confirmCardPayment(res.payment.id, {
							payment_method: {
								card: this.card,
								billing_details: {
									name: this.form.get('name').value,
									address: {
										line1: this.form.get('address').value,
										postal_code: this.form.get('zip').value,
									},
								},
							},
							shipping: {
								name,
								address: {
									line1: this.form.get('address').value,
									city: this.form.get('city').value,
									postal_code: this.form.get('zip').value,
									state: this.form.get('state').value,
								},
							},
						})
						.then((stripeRes) => {
							this.submitting = false;
							this.handleStripeResponse(stripeRes);
						})
						.catch(() => {
							// this should never happen
							this.alert.newAlert(
								'Something went wrong.',
								'danger'
							);
						});
				},
				(err) => {
					this.alert.newAlert(
						(err && err.error && err.error.message) ||
							'There was an error in submitting your payment.',
						'danger'
					);
				}
			);
	}

	handleStripeResponse({
		paymentIntent,
		error,
	}: {
		paymentIntent: { status: 'succeeded' | 'processing' };
		error: { type: string; message: string };
	}) {
		if (error && error.type === 'validation_error') {
			this.alert.newAlert(
				'Please check the form again and try resubmitting',
				'danger'
			);
		} else if (error) {
			this.alert.newAlert(error.message, 'danger');
		} else if (paymentIntent.status === 'succeeded') {
			// Success! Payment is confirmed. Update the interface to display the confirmation screen.
			// Update the note about receipt and shipping (the payment has been fully confirmed by the bank).
			this.resetForm();
			this.modal.close();
			this.success.next(`
				<p style="margin-bottom: 10px;">Thank you for registering.</p>
				<p>A confirmation email from ez-events.com has been sent to the email address provided. Please email info@ez-events.com if you do not find this in your inbox or SPAM/Junk Mail Folder.</p>
			`);
		} else if (paymentIntent.status === 'processing') {
			// Success! Now waiting for payment confirmation. Update the interface to display the confirmation screen.
			// Update the note about receipt and shipping (the payment is not yet confirmed by the bank).
			this.resetForm();
			this.modal.close();
			this.success.next(`
				<p>Thank you for registering.</p>
				<p>We’ll send your receipt as soon as your payment is confirmed.</p>
			`);
		} else {
			// Payment has failed.
			this.alert.newAlert('Payment has failed.', 'danger');
		}
	}

	resetForm() {
		this.submitting = false;
		this.card.clear();
		this.isCardComplete = false;
		this.cardError = null;
		this.form.reset();
		this.alert.closeAlert();
	}

	getCardMask(formControl: string) {
		return getCreditCardMask(this.form.get(formControl).value);
	}
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from 'rxjs/operators';
var EntityService = /** @class */ (function () {
    function EntityService(ezHttp) {
        this.ezHttp = ezHttp;
    }
    EntityService.prototype.generateBaseUrl = function (eventId) {
        return this.url + "/" + eventId + "/" + this.entity;
    };
    EntityService.prototype.getEventEntityList = function (eventId, limit, offset, queryParams) {
        if (limit === void 0) { limit = '25'; }
        if (offset === void 0) { offset = '0'; }
        if (queryParams === void 0) { queryParams = {}; }
        return this.ezHttp.get(this.generateBaseUrl(eventId) + "?limit=" + limit + "&offset=" + offset, {
            params: __assign({}, queryParams, { limit: limit,
                offset: offset }),
        });
    };
    EntityService.prototype.getEventEntityDetails = function (eventId, entityId) {
        return this.ezHttp
            .get(this.generateBaseUrl(eventId) + "/" + entityId)
            .pipe(map(function (entity) {
            return __assign({}, entity, { school_id: entity['school'] && entity['school']['id'] });
        }));
    };
    EntityService.prototype.updateEventEntity = function (eventId, entityId, payload) {
        return this.ezHttp.put(this.generateBaseUrl(eventId) + "/" + entityId, payload);
    };
    EntityService.prototype.createEventEntity = function (eventId, payload) {
        return this.ezHttp.post("" + this.generateBaseUrl(eventId), payload);
    };
    EntityService.prototype.removeEventEntity = function (eventId, entityId) {
        return this.ezHttp.delete(this.generateBaseUrl(eventId) + "/" + entityId);
    };
    EntityService.prototype.resendConfirmationEmail = function (eventId, entityId) {
        return this.ezHttp.post(this.generateBaseUrl(eventId) + "/" + entityId + "/confirmation", null);
    };
    return EntityService;
}());
export { EntityService };

import { OnInit, QueryList, AfterContentInit, EventEmitter, } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
var TabsetComponent = /** @class */ (function () {
    function TabsetComponent() {
        this.selectedTabIndex = 0;
        this.initTabIndex = 0;
        this.tabChange = new EventEmitter();
    }
    Object.defineProperty(TabsetComponent.prototype, "tabComponents", {
        get: function () {
            return this.tabs.toArray();
        },
        enumerable: true,
        configurable: true
    });
    TabsetComponent.prototype.ngOnInit = function () {
        this.selectedTabIndex = this.initTabIndex || 0;
    };
    TabsetComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        // added this b/c we were getting the "changed after checked" error
        console.log({ tabComponents: this.tabComponents });
        setTimeout(function () {
            if (!_this.tabComponents[_this.selectedTabIndex]) {
                return;
            }
            _this.tabComponents[_this.selectedTabIndex].selected = true;
        }, 300);
    };
    TabsetComponent.prototype.pickTab = function (index) {
        if (!this.tabComponents[this.selectedTabIndex]) {
            return;
        }
        this.tabComponents[this.selectedTabIndex].selected = false;
        this.selectedTabIndex = index;
        this.tabComponents[this.selectedTabIndex].selected = true;
        this.tabChange.next(this.selectedTabIndex);
    };
    return TabsetComponent;
}());
export { TabsetComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { includes } from '../utils/includes';
var InputDirective = /** @class */ (function () {
    function InputDirective(el, ngControl) {
        this.el = el;
        this.ngControl = ngControl;
        this.showValidation = false;
        this.externalError = null;
        // @Input() errorMessage = ''; // TODO - handle this later
        this.inputType = 'INPUT';
    }
    InputDirective.prototype.ngOnInit = function () {
        this.inputType = this.parseInputType(this.el.nativeElement);
    };
    InputDirective.prototype.parseInputType = function (element) {
        var tagName = element.tagName;
        var type = element.getAttribute('type');
        if (type === 'checkbox') {
            return type;
        }
        if (tagName === 'SELECT' && element['multiple']) {
            // TODO - fix me in the css or here to remove the icon for multi select elements
            return 'SELECT';
        }
        return tagName;
    };
    Object.defineProperty(InputDirective.prototype, "required", {
        get: function () {
            if (!this.ngControl) {
                return false;
            }
            var errors = this.ngControl.errors;
            if (errors) {
                var errorsArr = Object.keys(errors);
                return includes(errorsArr, 'required');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDirective.prototype, "showError", {
        get: function () {
            if (this.ngControl) {
                return (((this.ngControl && this.ngControl.invalid) ||
                    this.externalError) &&
                    (this.ngControl.touched || this.showValidation));
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDirective.prototype, "errors", {
        get: function () {
            var _a;
            var errors = this.ngControl.errors || {};
            var externalError = this.externalError;
            for (var key in externalError) {
                if (externalError.hasOwnProperty(key)) {
                    errors = __assign({}, errors, (_a = {}, _a[key] = externalError[key], _a));
                }
            }
            var errorArr = [];
            for (var key in errors) {
                if (errors.hasOwnProperty(key)) {
                    errorArr = this.composeErrorMessages(key, errors[key], errorArr);
                }
            }
            return errorArr;
        },
        enumerable: true,
        configurable: true
    });
    InputDirective.prototype.composeErrorMessages = function (key, errorObj, errorArr) {
        errorArr = this.parseRequiredError(key, errorObj, errorArr);
        errorArr = this.parseMinLengthError(key, errorObj, errorArr);
        errorArr = this.parseMaxLengthError(key, errorObj, errorArr);
        errorArr = this.parseEmailError(key, errorObj, errorArr);
        errorArr = this.parseMaxError(key, errorObj, errorArr);
        errorArr = this.parsePasswordMatchError(key, errorObj, errorArr);
        return errorArr;
    };
    InputDirective.prototype.parseRequiredError = function (key, errorObj, errorArray) {
        if (key !== 'required') {
            return errorArray.concat();
        }
        else {
            return errorArray.concat('*Required');
        }
    };
    InputDirective.prototype.parseMinLengthError = function (key, errorObj, errorArray) {
        if (key !== 'minlength') {
            return errorArray.concat();
        }
        else {
            var message = "Too short: " + errorObj['actualLength'] + "/" + errorObj['requiredLength'];
            return errorArray.concat(message);
        }
    };
    InputDirective.prototype.parseMaxLengthError = function (key, errorObj, errorArray) {
        if (key !== 'maxlength') {
            return errorArray.concat();
        }
        else {
            var message = "Too long: " + errorObj['actualLength'] + "/" + errorObj['requiredLength'];
            return errorArray.concat(message);
        }
    };
    InputDirective.prototype.parseEmailError = function (key, errorObj, errorArray) {
        if (key !== 'email') {
            return errorArray.concat();
        }
        else {
            var message = 'Invalid email';
            return errorArray.concat(message);
        }
    };
    InputDirective.prototype.parseMaxError = function (key, errorObj, errorArray) {
        if (key !== 'max') {
            return errorArray.concat();
        }
        else {
            var message = "Must be less than or equal to " + errorObj['max'];
            return errorArray.concat(message);
        }
    };
    InputDirective.prototype.parsePasswordMatchError = function (key, errorObj, errorArray) {
        if (key !== 'passwordsDontMatch') {
            return errorArray.concat();
        }
        else {
            var message = "Passwords do not match";
            return errorArray.concat(message);
        }
    };
    return InputDirective;
}());
export { InputDirective };

// services
import { _calculateAge, _scrollIt, } from './';
var ɵ0 = _calculateAge, ɵ1 = _scrollIt;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0, ɵ1 };

import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsService } from '../../../core/services/events-service/events.service';
import { stateList, getPhoneMask } from '../../constants';
var ContactComponent = /** @class */ (function () {
    function ContactComponent(eventService) {
        this.eventService = eventService;
        this.showValidation = true;
        this.isStandardEvent = true;
    }
    ContactComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(ContactComponent.prototype, "stateList", {
        get: function () {
            return stateList;
        },
        enumerable: true,
        configurable: true
    });
    ContactComponent.prototype.getPhoneMask = function (formControl) {
        return getPhoneMask(this.formGroup.get(formControl).value);
    };
    return ContactComponent;
}());
export { ContactComponent };

import { OnInit, } from '@angular/core';
import { Event } from '../../events/interfaces';
import { EventsService } from '../../core/services/events-service/events.service';
import { times } from '../times';
var MyRegistrationCardsComponent = /** @class */ (function () {
    function MyRegistrationCardsComponent(eventService) {
        this.eventService = eventService;
        this.competitorSortKey = 'name_first';
        this.competitorSortDirection = 'asc';
        this.getTimeCache = {};
    }
    Object.defineProperty(MyRegistrationCardsComponent.prototype, "eventCode", {
        get: function () {
            return this.event.code;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MyRegistrationCardsComponent.prototype, "showMyRegistrations", {
        get: function () {
            return (this.profile &&
                this.event &&
                !!this.profile.registrations[this.eventCode]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MyRegistrationCardsComponent.prototype, "schoolRegistrations", {
        get: function () {
            var registrations = this.profile.registrations[this.eventCode];
            var schoolMap = (registrations && registrations.schools) || {};
            var schools = [];
            for (var schoolKey in schoolMap) {
                var a = schoolMap[schoolKey];
                schools.push(a);
            }
            return schools;
        },
        enumerable: true,
        configurable: true
    });
    MyRegistrationCardsComponent.prototype.ngOnInit = function () { };
    MyRegistrationCardsComponent.prototype.getDivisions = function (competitor) {
        return Object.keys(competitor.divisions).map(function (key) { return competitor.divisions[key]; });
    };
    MyRegistrationCardsComponent.prototype.divisionsObjToArray = function (divisionObj) {
        var output = [];
        for (var key in divisionObj) {
            output.push(divisionObj[key]);
        }
        return output;
    };
    MyRegistrationCardsComponent.prototype.sortCompetitorsBy = function (field) {
        if (this.competitorSortKey !== field) {
            this.competitorSortKey = field;
            this.competitorSortDirection = 'asc';
        }
        else if (this.competitorSortKey === field &&
            this.competitorSortDirection === 'asc') {
            this.competitorSortDirection = 'desc';
        }
        else {
            this.competitorSortDirection = 'asc';
        }
    };
    MyRegistrationCardsComponent.prototype.sortCompetitors = function (competitors) {
        var _this = this;
        return competitors.sort(function (a, b) {
            if (_this.competitorSortKey === null) {
                return 1;
            }
            if (_this.competitorSortDirection === 'asc') {
                return a[_this.competitorSortKey] > b[_this.competitorSortKey]
                    ? 1
                    : -1;
            }
            return a[_this.competitorSortKey] < b[_this.competitorSortKey]
                ? 1
                : -1;
        });
    };
    MyRegistrationCardsComponent.prototype.getTime = function (timeString) {
        try {
            var cachedTime = this.getTimeCache[timeString];
            if (cachedTime) {
                return cachedTime;
            }
            var output = times.find(function (t) { return t.value === timeString; });
            this.getTimeCache[timeString] = output.display;
            return output.display;
        }
        catch (error) {
            return timeString;
        }
    };
    return MyRegistrationCardsComponent;
}());
export { MyRegistrationCardsComponent };

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, throwError } from 'rxjs';
import { tap, delay, mergeMap } from 'rxjs/operators';

@Injectable()
export class SessionService {
	url = '/api/v1/ez/sessions';
	isLoggedIn = false;

	constructor(private http: HttpClient) {}

	login(payload: { email: string; password: string }) {
		return this.http
			.post(this.url, {
				email: payload.email,
				password: payload.password
			})
			.pipe(
				tap(
					res => {
						this.isLoggedIn = true;
					},
					err => {
						this.isLoggedIn = false;
					}
				)
			);

		// return this.mockLogin(payload);
	}

	logout() {
		return this.http.delete(this.url).pipe(
			tap(
				res => {
					this.isLoggedIn = false;
				},
				err => {
					this.isLoggedIn = false;
				}
			)
		);
	}

	createAccount(payload) {
		return this.http.post('/api/v1/ez/logins', payload).pipe(
			tap(
				res => {
					this.isLoggedIn = true;
				},
				err => {
					this.isLoggedIn = false;
				}
			)
		);
	}

	mockLogin(payload) {
		return of(true).pipe(
			delay(1000),
			mergeMap(res => {
				if (payload.password === '123') {
					return of(res);
				}
				return throwError(false);
			})
		);
	}

	forgotPassword(payload: { email: string }) {
		return this.http.post('/api/v1/ez/logins/reset', payload);
	}

	resetPassword(
		verificationKey: string,
		payload: { email: string; password: string }
	) {
		return this.http.post(
			`/api/v1/ez/logins/reset/${verificationKey}`,
			payload
		);
	}
}

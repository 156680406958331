import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'eze-list-search-form',
	templateUrl: './list-search-form.component.html',
	styleUrls: ['./list-search-form.component.css'],
})
export class ListSearchFormComponent implements OnInit {
	@Input()
	searchForm: FormGroup;

	@Output() onSubmit = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	performSearch() {
		this.onSubmit.emit();
	}

	clearSearch() {
		this.searchForm.reset();
		this.onSubmit.emit();
	}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-container.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../../shared/navbar/navbar.component";
import * as i4 from "../services/session-service/session.service";
import * as i5 from "../services/profile-service/profile.service";
import * as i6 from "../services/build.service";
import * as i7 from "@angular/router";
import * as i8 from "./app-container.component";
var styles_AppContainerComponent = [i0.styles];
var RenderType_AppContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppContainerComponent, data: {} });
export { RenderType_AppContainerComponent as RenderType_AppContainerComponent };
export function View_AppContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eze-navbar", [], null, [[null, "logoutSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logoutSuccess" === en)) {
        var pd_0 = (_co.goToLoginPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 245760, null, 0, i3.NavbarComponent, [i4.SessionService, i5.ProfileService, i6.BuildService], { enableLogout: [0, "enableLogout"] }, { logoutSuccess: "logoutSuccess" }), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_AppContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eze-app-container", [], null, null, null, View_AppContainerComponent_0, RenderType_AppContainerComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppContainerComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppContainerComponentNgFactory = i1.ɵccf("eze-app-container", i8.AppContainerComponent, View_AppContainerComponent_Host_0, {}, {}, []);
export { AppContainerComponentNgFactory as AppContainerComponentNgFactory };

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'eze-app-container',
	templateUrl: './app-container.component.html',
	styleUrls: ['./app-container.component.css']
})
export class AppContainerComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit() {}

	goToLoginPage() {
		this.router.navigate(['/ez/login']);
	}
}

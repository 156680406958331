import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'eze-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
	private _listResponse = null;
	@Input() 
	set listResponse(input) {
		this.generatePaginationArray(input.limit, input.offset, input.total);
		this._listResponse = input;
	}
	get listResponse() {
		return this._listResponse;
	}

	paginationArray = [];

	goToValueControl = new FormControl();

	currentPage: number;

	@Output() goToPage: EventEmitter<any> = new EventEmitter();

	get paginationObject() {
		return this.listResponse;
	}

	get fromValue(): string {
		const output = this.paginationObject.offset + 1;
		return output;
	}

	get toValue(): string {
		return this.paginationObject.offset + this.paginationObject.data.length;
	}

	constructor() {}

	ngOnInit() {

	}

	// components access this function through ViewChild
	updatePaginationLength(length: number) {
		this.paginationObject.length = length;
	}

	clickPage(page) {
		if (page.disabled) return;
		this.goToPage.emit(page);
	}

	// clickGo() {
	// 	const page: number = this.goToValueControl.value;

	// 	const limit = this.paginationObject.limit;
	// 	const newOffset = limit * (page - 1);
	// 	let active = false;

	// 	if (newOffset === this.paginationObject.offset) active = true; // makes sure to not update query string

	// 	const pageObj = {
	// 		active: active,
	// 		disabled: false,
	// 		limit: limit,
	// 		offset: limit * (page - 1)
	// 	};

	// 	// If Page number out of bounds, then default to last page
	// 	if (pageObj.offset >= this.paginationObject.total) {
	// 		pageObj.offset =
	// 			this.paginationObject.total -
	// 			this.paginationObject.total % pageObj.limit;
	// 	}

	// 	this.goToPage.emit(pageObj);
	// }

	// generates pagination array
	generatePaginationArray(limit, offset, total) {
		this.paginationArray = []; // reset pagination array
		limit = Number(limit);
		offset = Number(offset);
		total = Number(total);

		const totalPages = Math.ceil(total / limit);
		this.getCurrentPage(offset, limit);
		// start building the pagination array
		if (totalPages === 1) return; // don't show pagination if only 1 page

		this.paginationArray = [
			{
				text: 'First',
				limit: limit,
				offset: 0,
				active: false,
				disabled: false
			},
			{
				text: 'Previous',
				limit: limit,
				offset: (this.currentPage - 2) * limit,
				active: false,
				disabled: false
			}
		];
		if (this.currentPage === 1) {
			this.paginationArray[0].disabled = true;
			this.paginationArray[1].disabled = true;
			if (totalPages > 1) {
				for (let i = 1; i <= (totalPages < 3 ? totalPages : 3); i++) {
					this.paginationArray.push({
						text: i,
						limit: limit,
						offset: (i - 1) * limit,
						active: this.currentPage === i ? true : false,
						disabled: false
					});
				}
			}
			this.paginationArray = this.addNextLast(limit, totalPages);
		} else if (this.currentPage !== 1 && this.currentPage !== totalPages) {
			// a middle page
			// intercept if current page is greater than total pages
			if (this.currentPage > totalPages) {
				this.paginationArray = this.addNextLast(limit, totalPages);
				this.paginationArray[1].disabled = true;
				this.paginationArray[2].disabled = true;
				return;
			}

			for (
				let i = this.currentPage < 3 ? 1 : this.currentPage - 2;
				i <=
				(this.currentPage + 2 > totalPages
					? totalPages
					: this.currentPage + 2);
				i++
			) {
				this.paginationArray.push({
					text: i,
					limit: limit,
					offset: (i - 1) * limit,
					active: this.currentPage === i ? true : false,
					disabled: false
				});
			}
			this.paginationArray = this.addNextLast(limit, totalPages);
		} else if (this.currentPage === totalPages) {
			for (
				let i = totalPages === 2 ? 1 : totalPages - 2;
				i <= (totalPages === 2 ? 2 : totalPages);
				i++
			) {
				this.paginationArray.push({
					text: i,
					limit: limit,
					offset: (i - 1) * limit,
					active: this.currentPage === i ? true : false
				});
			}
			this.paginationArray.push(
				{
					text: 'Next',
					disabled: true,
					active: false
				},
				{
					text: 'Last',
					disabled: true,
					active: false
				}
			);
		}
	}

	getCurrentPage(offset: number, limit: number) {
		this.currentPage = offset / limit + 1;
	}

	addNextLast(limit, totalPages) {
		return this.paginationArray.concat([
			{
				text: 'Next',
				limit: limit,
				offset: this.currentPage * limit,
				active: false,
				disabled: false
			},
			{
				text: 'Last',
				limit: limit,
				offset: (totalPages - 1) * limit,
				active: false,
				disabled: false
			}
		]);
	}

	// sanitizePageNum() {
	// 	let newVal = Sanitize.stringToNumber(Sanitize.onlyAllowNumber(this.goToValueControl.value));
	// 	this.goToValueControl.patchValue(newVal);
	// }
}

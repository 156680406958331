import { Validators } from '@angular/forms';


export class ContactForm {
	email = ['', [Validators.required, Validators.email]];
	phone = ['', Validators.required];
	address = [''];
	address_2 = [''];
	city = 	[''];
	state = [''];
	zip = [''];
}
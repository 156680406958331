import { Directive, HostListener, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { TagContentType } from '@angular/compiler';

@Directive({
	selector: '[ezeClickOutside]'
})
export class ClickOutsideDirective {

	@Output()
	ezeClickOutside = new EventEmitter<any>();

	constructor(private element: ElementRef) {}

	@HostListener('document:click', ['$event.target'])
	click(target: EventTarget) {
		if (!this.element.nativeElement.contains(target)) {
			this.ezeClickOutside.emit();
		}
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { SessionService } from '../session-service/session.service';

@Injectable()
export class EzHttpService {
	constructor(
		private http: HttpClient,
		private sessionService: SessionService,
		private router: Router
	) {}

	get(url: string, options?): Observable<any> {
		return this.http
			.get(url, options).pipe(
				tap(res => {
					this.sessionService.isLoggedIn = true;
				}),
			);
	}
}

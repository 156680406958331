import {
	Injectable,
	InjectionToken,
	Inject,
	ErrorHandler,
} from '@angular/core';
import { environment } from '../../../../environments/environment';

import * as Rollbar from 'rollbar';

let envString = 'development';
if (environment.production) {
	envString = 'production';
}

const rollbarConfig = {
	accessToken: '07beba9f9b2d4fd8adf0d2bacc4feaa8',
	captureUncaught: true,
	captureUnhandledRejections: true,
	payload: {
		environment: envString,
	},
	autoInstrument: {
		log: false,
	},
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
	return new Rollbar(rollbarConfig);
}

@Injectable()
export class RollbarErrorHandlerService implements ErrorHandler {
	constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

	handleError(err: any): void {
		if (environment.production) {
			console.error(err.originalError || err);
			this.rollbar.error(err.originalError || err);
			return;
		}
		console.error(err.originalError || err);
	}
}

import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ControlValueAccessor } from '@angular/forms';
import { map, filter } from 'rxjs/operators';
import { months, days, generatePastYears } from '../../constants';
var BirthDateComponent = /** @class */ (function () {
    function BirthDateComponent(fb) {
        this.fb = fb;
        this.youngestAge = 4;
    }
    Object.defineProperty(BirthDateComponent.prototype, "months", {
        get: function () {
            return months;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BirthDateComponent.prototype, "days", {
        get: function () {
            return days;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BirthDateComponent.prototype, "years", {
        get: function () {
            return this._years;
        },
        enumerable: true,
        configurable: true
    });
    BirthDateComponent.prototype.ngOnInit = function () {
        this.birth_date_helpers = this.fb.group({
            // helper controls, not used by API
            birth_month: ['', Validators.required],
            birth_day: ['', Validators.required],
            birth_year: ['', Validators.required]
        });
        this._years = generatePastYears(this.youngestAge).map(function (num) {
            return num.toString();
        });
    };
    BirthDateComponent.prototype.writeValue = function (value) {
        var date = ['', '', ''];
        if (value) {
            date = value.split('-');
        }
        this.birth_date_helpers.setValue({
            birth_month: date[1],
            birth_day: date[2],
            birth_year: date[0]
        });
    };
    BirthDateComponent.prototype.registerOnChange = function (fn) {
        this.birth_date_helpers.markAsUntouched();
        if (this.valueChagnesSub) {
            this.valueChagnesSub.unsubscribe();
        }
        this.valueChagnesSub = this.birth_date_helpers.valueChanges
            .pipe(filter(function (value) {
            return (value.birth_year && value.birth_month && value.birth_day);
        }), map(function (value) {
            var birthDateString = value.birth_year + "-" + value.birth_month + "-" + value.birth_day;
            return birthDateString;
        }))
            .subscribe(fn);
    };
    BirthDateComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    BirthDateComponent.prototype.setDisabledState = function (disabled) {
        if (disabled) {
            this.birth_date_helpers.disable();
        }
        else {
            this.birth_date_helpers.enable();
        }
    };
    BirthDateComponent.prototype.ngOnDestroy = function () {
        if (this.valueChagnesSub) {
            this.valueChagnesSub.unsubscribe();
        }
    };
    return BirthDateComponent;
}());
export { BirthDateComponent };

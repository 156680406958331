import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class BuildService {
	constructor(private http: HttpClient) {}
	currentBuild: string | null = null;
	interval: any;

	getBuild() {
		return this.http.get<{ build: string }>('/api/v1/build');
	}
}

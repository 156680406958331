import { Component, OnInit, Input, NgZone } from '@angular/core';
import {
	trigger,
	state,
	style,
	transition,
	animate
} from '@angular/animations';

import { NavigationTabs } from './NavigationTabs.interface';

@Component({
	selector: 'eze-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.css'],
	animations: [
		trigger('slideState', [
			state(
				'open',
				style({
					height: '*',
					'padding-top': '7px',
					'padding-bottom': '7px'
				})
			),
			state(
				'closed',
				style({
					height: '0',
					'padding-top': '0px',
					'padding-bottom': '0px'
				})
			),
			transition('closed => open', [
				style({ height: 0 }),
				animate(
					'.3s ease',
					style({
						height: '*',
						'padding-top': '7px',
						'padding-bottom': '7px'
					})
				)
			]),
			transition('open => closed', [
				style({ height: '*' }),
				animate(
					'.3s ease',
					style({
						height: '0',
						'padding-top': '0px',
						'padding-bottom': '0px'
					})
				)
			])
		])
	]
})
export class SideMenuComponent implements OnInit {
	@Input() navTabs: NavigationTabs[];
	open: number;

	constructor(private ngZone: NgZone) {}

	ngOnInit() {
		this.setBodyClass();
		window.onresize = () => {
			// ensure that this callback will trigger angular change detection by running in ngZone
			this.ngZone.run(() => {
				this.setBodyClass();
			});
		};
	}

	findState(i) {
		if (this.open === i) {
			return 'open';
		}
		if (this.open !== i) {
			return 'closed';
		}
	}

	click(i) {
		if (this.open === i) {
			// if already open, close it
			this.open = -1;
			return;
		}
		this.open = i;
	}

	setBodyClass() {
		if (this.getOuterWidth() < 980) {
			if (
				document.body.className.indexOf('mobile-view-activated') === -1
			) {
				document.body.className += ' mobile-view-activated';
				document.body.className = document.body.className.replace(
					/minified\b/,
					''
				);
			}
		} else {
			document.body.className = document.body.className.replace(
				/mobile-view-activated\b/,
				''
			);
			document.body.className = document.body.className.replace(
				/hidden-menu\b/,
				''
			);
		}
	}

	minify() {
		if (document.body.className.indexOf('minified') !== -1) {
			document.body.className = document.body.className.replace(
				/minified\b/,
				''
			);
		} else {
			document.body.className += ' minified';
		}
	}

	getOuterWidth() {
		return window.outerWidth;
	}

	closeNavbarOnMobile() {
		document.body.className = document.body.className.replace(
			/hidden-menu\b/,
			''
		);
	}
}

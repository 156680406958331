import {
	Directive,
	Input,
	OnInit,
	ElementRef,
	Optional,
	Self,
	Renderer2
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { includes } from '../utils/includes';

@Directive({
	selector: '[ezeInput]'
})
export class InputDirective implements OnInit {
	@Input() id;
	@Input() showValidation = false;
	@Input() externalError = null;
	// @Input() errorMessage = ''; // TODO - handle this later
	inputType = 'INPUT';

	constructor(
		private el: ElementRef,
		@Optional() @Self() private ngControl: NgControl
	) {}

	ngOnInit() {
		this.inputType = this.parseInputType(this.el.nativeElement);
	}

	parseInputType(element: HTMLElement): string {
		const tagName = element.tagName;
		const type = element.getAttribute('type');

		if (type === 'checkbox') {
			return type;
		}

		if (tagName === 'SELECT' && element['multiple']) {
			// TODO - fix me in the css or here to remove the icon for multi select elements
			return 'SELECT';
		}

		return tagName;
	}

	get required(): boolean {
		if (!this.ngControl) {
			return false;
		}

		const errors = this.ngControl.errors;
		if (errors) {
			const errorsArr = Object.keys(errors);
			return includes(errorsArr, 'required');
		}
	}

	get showError(): boolean {
		if (this.ngControl) {
			return (
				((this.ngControl && this.ngControl.invalid) ||
					this.externalError) &&
				(this.ngControl.touched || this.showValidation)
			);
		} else {
			return false;
		}
	}

	get errors(): string[] {
		let errors = this.ngControl.errors || {};

		const externalError = this.externalError;
		for (const key in externalError) {
			if (externalError.hasOwnProperty(key)) {
				errors = { ...errors, [key]: externalError[key] };
			}
		}

		let errorArr = [];

		for (const key in errors) {
			if (errors.hasOwnProperty(key)) {
				errorArr = this.composeErrorMessages(
					key,
					errors[key],
					errorArr
				);
			}
		}

		return errorArr;
	}

	composeErrorMessages(key: string, errorObj: {}, errorArr): string[] {
		errorArr = this.parseRequiredError(key, errorObj, errorArr);
		errorArr = this.parseMinLengthError(key, errorObj, errorArr);
		errorArr = this.parseMaxLengthError(key, errorObj, errorArr);
		errorArr = this.parseEmailError(key, errorObj, errorArr);
		errorArr = this.parseMaxError(key, errorObj, errorArr);
		errorArr = this.parsePasswordMatchError(key, errorObj, errorArr);

		return errorArr;
	}

	parseRequiredError(
		key: string,
		errorObj: {},
		errorArray: string[]
	): string[] {
		if (key !== 'required') {
			return errorArray.concat();
		} else {
			return errorArray.concat('*Required');
		}
	}

	parseMinLengthError(
		key: string,
		errorObj: {},
		errorArray: string[]
	): string[] {
		if (key !== 'minlength') {
			return errorArray.concat();
		} else {
			const message = `Too short: ${errorObj['actualLength']}/${errorObj['requiredLength']}`;
			return errorArray.concat(message);
		}
	}

	parseMaxLengthError(
		key: string,
		errorObj: {},
		errorArray: string[]
	): string[] {
		if (key !== 'maxlength') {
			return errorArray.concat();
		} else {
			const message = `Too long: ${errorObj['actualLength']}/${errorObj['requiredLength']}`;
			return errorArray.concat(message);
		}
	}

	parseEmailError(key: string, errorObj: {}, errorArray: string[]): string[] {
		if (key !== 'email') {
			return errorArray.concat();
		} else {
			const message = 'Invalid email';
			return errorArray.concat(message);
		}
	}

	parseMaxError(key: string, errorObj: {}, errorArray: string[]): string[] {
		if (key !== 'max') {
			return errorArray.concat();
		} else {
			const message = `Must be less than or equal to ${errorObj['max']}`;
			return errorArray.concat(message);
		}
	}

	parsePasswordMatchError(
		key: string,
		errorObj: {},
		errorArray: string[]
	): string[] {
		if (key !== 'passwordsDontMatch') {
			return errorArray.concat();
		} else {
			const message = `Passwords do not match`;
			return errorArray.concat(message);
		}
	}
}

<div
	class="modal fade"
	id="myModal"
	tabindex="-1"
	role="dialog"
	aria-labelledby="modal"
	[class.in]="isOpen"
	[ngStyle]="{ display: isOpen ? 'block' : 'none' }"
	(click)="handleOutsideClick($event)"
	#modalContainer
>
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button
					*ngIf="canDismiss"
					type="button"
					class="close"
					data-dismiss="modal"
					aria-label="Close"
					(click)="close()"
				>
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title" id="myModalLabel">
					<ng-content select="eze-modal-header"></ng-content>
				</h4>
			</div>
			<div class="modal-body">
				<ng-content select="eze-modal-content"></ng-content>
			</div>
			<div class="modal-footer">
				<ng-content select="eze-modal-footer"></ng-content>
			</div>
		</div>
	</div>
</div>

<div
	class="modal-backdrop fade"
	[class.in]="isOpen"
	[ngStyle]="{ display: isOpen ? 'block' : 'none' }"
></div>

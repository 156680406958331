import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'eze-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnInit {
	@Input() type = 'button';
	@Input() context = 'primary';
	@Input() noStyle = false;
	@Input() disabled = false;

	constructor() {}

	ngOnInit() {}
}

import { Injectable } from '@angular/core';
import { School } from '../../events/interfaces';

@Injectable()
export class GenerateSchoolNameService {

	constructor() { }

	generate(school: School): string {
		let output = school.name;

		if (school.city) {
			output += ` - (${school.city})`;
		}

		return output;
	}
}

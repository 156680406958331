import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'eze-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
	@Input() alert: Alert;
	@Input() disableClose = false;

	constructor() { }

	ngOnInit() {
	}

	closeAlert() {
		delete this.alert;
	}

	newAlert(message: string, contextClass: 'danger'|'warning'|'success'|'info') {
		this.alert = {
			message: message,
			contextClass: contextClass,
		};
	}

}

export interface Alert {
	contextClass: 'danger'|'warning'|'success'|'info';
	message: string;
}


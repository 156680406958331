import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
	FormGroup,
	FormBuilder,
	Validators,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { months, Month, days, generatePastYears } from '../../constants';

@Component({
	selector: 'eze-birth-date',
	templateUrl: './birth-date.component.html',
	styleUrls: ['./birth-date.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: BirthDateComponent
		}
	]
})
export class BirthDateComponent
	implements OnInit, ControlValueAccessor, OnDestroy {
	birth_date_helpers: FormGroup;

	@Input()
	showValidation: boolean;

	@Input()
	youngestAge = 4;

	valueChagnesSub: Subscription;

	onTouched: () => void;

	get months(): Month[] {
		return months;
	}

	get days(): string[] {
		return days;
	}

	_years: string[];
	get years(): string[] {
		return this._years;
	}

	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		this.birth_date_helpers = this.fb.group({
			// helper controls, not used by API
			birth_month: ['', Validators.required],
			birth_day: ['', Validators.required],
			birth_year: ['', Validators.required]
		});

		this._years = generatePastYears(this.youngestAge).map(num =>
			num.toString()
		);
	}

	writeValue(value) {
		let date = ['', '', ''];

		if (value) {
			date = value.split('-');
		}

		this.birth_date_helpers.setValue({
			birth_month: date[1],
			birth_day: date[2],
			birth_year: date[0]
		});
	}

	registerOnChange(fn: (value: any) => void) {
		this.birth_date_helpers.markAsUntouched();

		if (this.valueChagnesSub) {
			this.valueChagnesSub.unsubscribe();
		}

		this.valueChagnesSub = this.birth_date_helpers.valueChanges
			.pipe(
				filter(value => {
					return (
						value.birth_year && value.birth_month && value.birth_day
					);
				}),
				map(value => {
					const birthDateString = `${value.birth_year}-${value.birth_month}-${value.birth_day}`;
					return birthDateString;
				})
			)
			.subscribe(fn);
	}

	registerOnTouched(fn: () => void) {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.birth_date_helpers.disable();
		} else {
			this.birth_date_helpers.enable();
		}
	}

	ngOnDestroy() {
		if (this.valueChagnesSub) {
			this.valueChagnesSub.unsubscribe();
		}
	}
}

export const years = [];

const currentYear = new Date().getFullYear();

for (let i = currentYear - 4; i > currentYear - 90; i--) {
	years.push(i);
}

export function generatePastYears(offset = 4, range = 90): Number[] {
	const output = [];

	const thisYear = new Date().getFullYear();

	for (let i = thisYear - offset; i >= thisYear - range; i--) {
		output.push(i);
	}

	return output;
}

export function generateFutureYears(offset = 0, range = 20): Number[] {
	const output = [];

	const thisYear = new Date().getFullYear();

	for (let i = thisYear - offset; i < thisYear + range; i++) {
		output.push(i);
	}

	return output;
}
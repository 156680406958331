import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EzModalComponent } from './ez-modal/ez-modal.component';
import { ModalContentComponent } from './modal-helpers/modal-content.component';
import { ModalHeaderComponent } from './modal-helpers/modal-header.component';
import { ModalFooterComponent } from './modal-helpers/modal-footer.component';

@NgModule({
	imports: [CommonModule],
	declarations: [
		EzModalComponent,
		ModalHeaderComponent,
		ModalContentComponent,
		ModalFooterComponent
	],
	exports: [
		EzModalComponent,
		ModalHeaderComponent,
		ModalContentComponent,
		ModalFooterComponent
	]
})
export class EzModalModule {}

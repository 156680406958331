import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, take, map, filter } from 'rxjs/operators';

import { EzHttpService } from '../ez-http/ez-http.service';

import { Profile } from '../../interfaces/profile.interface';
import { FormGroup } from '@angular/forms';
import { SessionService } from '../session-service/session.service';

export interface EventPermissions {
	[key: string]: { [key: string]: Boolean };
}

export type PermissionResource =
	| 'coach'
	| 'competitor'
	| 'master'
	| 'owner'
	| 'referee'
	| 'volunteer'
	| 'api_docs'
	| 'reports'
	| 'ez2';

// example permissions
// api_docs_view: true
// coach_edit: true
// coach_view: true
// competitor_edit: true
// competitor_view: true
// competitor_weighin: true
// ez2: true
// ez2_admin: true
// master_edit: false
// master_view: false
// owner_edit: false
// owner_view: false
// referee_edit: false
// referee_view: false
// volunteer_edit: false
// volunteer_view: false

@Injectable()
export class ProfileService {
	url = '/api/v1/ez/me';
	profile = new BehaviorSubject<Profile | {}>(null);
	eventPermissions = new BehaviorSubject<EventPermissions | {}>(null);
	isGuest = false;

	constructor(
		private ezHttp: EzHttpService,
		private sessionService: SessionService
	) {}

	getProfile(): Observable<Profile> {
		return this.ezHttp.get(this.url).pipe(
			map((profile: Profile) => {
				if (profile === null) {
					// logged in as guest
					return {};
				}
				const output: any = { ...profile };
				output.gender = output.gender === 'M' ? 'Male' : 'Female';
				output.height_ft =
					output.height_ft === '0' ? '' : output.height_ft;
				output.height_in =
					output.height_in === '0' ? '' : output.height_in;
				output.weight = output.weight === '0.00' ? '' : output.weight;
				return output;
			}),
			tap((profile) => this.profile.next(profile)),
			tap((profile: Profile) => {
				const eventPermissions: EventPermissions = {};
				profile.events.forEach((event) => {
					eventPermissions[event.code] = event.permissions;
					// eventPermissions[event.code] = {
					// 	coach_view: true,
					// 	coach_edit: true,
					// 	competitor_view: true,
					// 	competitor_edit: true,
					// 	competitor_weighin: false,
					// 	master_view: false,
					// 	master_edit: false,
					// 	owner_view: false,
					// 	owner_edit: false,
					// 	referee_view: true,
					// 	referee_edit: true,
					// 	volunteer_view: false,
					// 	volunteer_edit: false,
					// 	api_docs_view: false,
					// 	reports_view: true,
					// 	reports_brackets: true,
					// 	ez2: true,
					// 	ez2_admin: true,
					// 	ez2_status: true,
					// 	ez2_checkin: true,
					// 	ez2_weighin: true,
					// 	ez2_brackets: true,
					// 	ez2_results: true,
					// 	ez2_schedule: true,
					// };
				});
				this.eventPermissions.next(eventPermissions);
			})
		);
	}

	patchFormWithProfile(form: FormGroup) {
		this.profile
			.pipe(
				take(1),
				filter((data) => !!data)
			)
			.subscribe((data) => {
				form.patchValue(data);
			});
	}

	logoutUser() {
		return this.sessionService.logout().pipe(
			tap(() => {
				this.profile.next(null);
			})
		);
	}

	loginAsGuest() {
		this.isGuest = true;
		this.profile.next({});
	}

	hasPermission(
		eventCode: string,
		resource: PermissionResource,
		actions: string[]
	): boolean {
		const permissions = this.eventPermissions.value[eventCode];
		if (!permissions || !actions) {
			return false;
		}

		let returnValue = false;
		actions.forEach((action) => {
			const permissionKey = `${resource}_${action}`;
			returnValue = returnValue || permissions[permissionKey];
		});

		return returnValue;
	}

	// TODO - fix me?
	hasMiscPersmission(eventCode: string, perm: string): boolean {
		const permissions = this.eventPermissions.value[eventCode];
		if (!permissions) {
			return false;
		}

		return permissions[perm];
	}
}


import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// modules
import { CoreRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';

// services
import { RollbarErrorHandlerService, RollbarService, rollbarFactory } from './services/rollbar-error-handler/rollbar-error-handler.service';
import { EzHttpService } from './services/ez-http/ez-http.service';
import { EventsService } from './services/events-service/events.service';
import { SessionService } from './services/session-service/session.service';
import { ProfileService } from './services/profile-service/profile.service';
import { ProfileResolverService } from './router-guards/profile-resolver/profile-resolver.service';
import { CompetitorsService } from './services/competitors-service/competitors.service';
import { NewCompetitorsService } from './services/competitors-service/new-competitors.service';

// router guards
import { EventResolverService } from './router-guards/event-resolver/event-resolver.service';

// components
import { AppContainerComponent } from './app-container/app-container.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ConsoleComponent } from './console/console.component';
import { EventRegistrationContainerComponent } from './event-registration-container/event-registration-container.component';

@NgModule({
	imports: [
		CommonModule,
		CoreRoutingModule,
		SharedModule,
		HttpClientModule,
	],
	declarations: [
		AppContainerComponent,
		NotFoundComponent,
		HomePageComponent,
		ConsoleComponent,
		EventRegistrationContainerComponent,
	],
	exports: [
		CoreRoutingModule,
		AppContainerComponent,
	],
	providers: [
		{ provide: ErrorHandler, useClass: RollbarErrorHandlerService },
		{ provide: RollbarService, useFactory: rollbarFactory },
		EzHttpService,
		EventsService,
		EventResolverService,
		SessionService,
		ProfileService,
		ProfileResolverService,
		CompetitorsService,
		NewCompetitorsService
	]
})
export class CoreModule { }

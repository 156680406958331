/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./side-menu.component";
var styles_SideMenuComponent = [i0.styles];
var RenderType_SideMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideMenuComponent, data: { "animation": [{ type: 7, name: "slideState", definitions: [{ type: 0, name: "open", styles: { type: 6, styles: { height: "*", "padding-top": "7px", "padding-bottom": "7px" }, offset: null }, options: undefined }, { type: 0, name: "closed", styles: { type: 6, styles: { height: "0", "padding-top": "0px", "padding-bottom": "0px" }, offset: null }, options: undefined }, { type: 1, expr: "closed => open", animation: [{ type: 6, styles: { height: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { height: "*", "padding-top": "7px", "padding-bottom": "7px" }, offset: null }, timings: ".3s ease" }], options: null }, { type: 1, expr: "open => closed", animation: [{ type: 6, styles: { height: "*" }, offset: null }, { type: 4, styles: { type: 6, styles: { height: "0", "padding-top": "0px", "padding-bottom": "0px" }, offset: null }, timings: ".3s ease" }], options: null }], options: {} }] } });
export { RenderType_SideMenuComponent as RenderType_SideMenuComponent };
function View_SideMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "pointer"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 6, "a", [], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { width: 0, "font-size": 1 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "menu-item-parent"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.route; _ck(_v, 1, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 2, 0, currVal_1); var currVal_4 = _ck(_v, 9, 0, (_v.parent.context.$implicit.width ? _v.parent.context.$implicit.width : "2.1em"), (_v.parent.context.$implicit.fontSize ? _v.parent.context.$implicit.fontSize : "1em")); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.label, ""); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 6, 0, currVal_3); var currVal_5 = _v.parent.context.$implicit.label; _ck(_v, 11, 0, currVal_5); }); }
function View_SideMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [], [[8, "title", 0], [8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { width: 0, "font-size": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "menu-item-parent"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _ck(_v, 5, 0, (_v.parent.context.$implicit.width ? _v.parent.context.$implicit.width : "2.1em"), (_v.parent.context.$implicit.fontSize ? _v.parent.context.$implicit.fontSize : "1em")); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.label, ""); var currVal_1 = _v.parent.context.$implicit.externalRoute; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 2, 0, currVal_2); var currVal_4 = _v.parent.context.$implicit.label; _ck(_v, 7, 0, currVal_4); }); }
function View_SideMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "fa fa-plus-square-o"]], null, null, null, null, null))], null, null); }
function View_SideMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "fa fa-minus-square-o"]], null, null, null, null, null))], null, null); }
function View_SideMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.route; _ck(_v, 1, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.label; _ck(_v, 6, 0, currVal_2); }); }
function View_SideMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.externalRoute; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_1); }); }
function View_SideMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_8)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_9)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.externalRoute; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.externalRoute; _ck(_v, 3, 0, currVal_1); }, null); }
function View_SideMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "li", [["class", "pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { width: 0, "font-size": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "menu-item-parent"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "b", [["class", "collapse-sign"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_5)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_6)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ul", [["style", "display: block;"]], [[24, "@slideState", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_7)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 5, 0, (_v.parent.context.$implicit.width ? _v.parent.context.$implicit.width : "2.1em"), (_v.parent.context.$implicit.fontSize ? _v.parent.context.$implicit.fontSize : "1em")); _ck(_v, 4, 0, currVal_1); var currVal_3 = (_co.open !== _v.parent.context.index); _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.open === _v.parent.context.index); _ck(_v, 12, 0, currVal_4); var currVal_6 = _v.parent.context.$implicit.children; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.label; _ck(_v, 7, 0, currVal_2); var currVal_5 = _co.findState(_v.parent.context.index); _ck(_v, 13, 0, currVal_5); }); }
function View_SideMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_2)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (!_v.context.$implicit.children && !_v.context.$implicit.externalRoute); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_v.context.$implicit.children && _v.context.$implicit.externalRoute); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.children; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SideMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "aside", [["class", "print1"], ["id", "left-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeNavbarOnMobile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "minifyme"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.minify() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-arrow-circle-left hit txt-color-white"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navTabs; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SideMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eze-side-menu", [], null, null, null, View_SideMenuComponent_0, RenderType_SideMenuComponent)), i1.ɵdid(1, 114688, null, 0, i4.SideMenuComponent, [i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideMenuComponentNgFactory = i1.ɵccf("eze-side-menu", i4.SideMenuComponent, View_SideMenuComponent_Host_0, { navTabs: "navTabs" }, {}, []);
export { SideMenuComponentNgFactory as SideMenuComponentNgFactory };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, EventEmitter, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { includes } from '../utils/includes';
import { generateFutureYears, months, getCreditCardMask } from '../constants';
import { EventsService } from '../../core/services/events-service/events.service';
import { AlertComponent } from '../alert/alert.component';
var StripePaymentModalComponent = /** @class */ (function () {
    function StripePaymentModalComponent(formBuilder, eventsService) {
        this.formBuilder = formBuilder;
        this.eventsService = eventsService;
        this.success = new EventEmitter();
        this.showValidation = false;
        this.years = [];
        this.submitting = false;
        this.stripeId = null;
        this.stripe = null;
        this.card = null;
        this.isCardComplete = false;
        this.cardError = null;
        this.stripeCountries = [
            { country: 'Australia', code: 'AU' },
            { country: 'Austria', code: 'AT' },
            { country: 'Belgium', code: 'BE' },
            { country: 'Bulgaria', code: 'BG' },
            { country: 'Brazil ', code: 'BR' },
            { country: 'Canada', code: 'CA' },
            { country: 'Cyprus', code: 'CY' },
            { country: 'Czech Republic', code: 'CZ' },
            { country: 'Denmark', code: 'DK' },
            { country: 'Estonia', code: 'EE' },
            { country: 'Finland', code: 'FI' },
            { country: 'France', code: 'FR' },
            { country: 'Germany', code: 'DE' },
            { country: 'Greece', code: 'GR' },
            { country: 'Hong Kong', code: 'HK' },
            { country: 'India', code: 'IN' },
            { country: 'Ireland', code: 'IE' },
            { country: 'Italy', code: 'IT' },
            { country: 'Japan', code: 'JP' },
            { country: 'Latvia', code: 'LV' },
            { country: 'Lithuania', code: 'LT' },
            { country: 'Luxembourg', code: 'LU' },
            { country: 'Malaysia', code: 'MY' },
            { country: 'Malta', code: 'MT' },
            { country: 'Mexico ', code: 'MX' },
            { country: 'Netherlands', code: 'NL' },
            { country: 'New Zealand', code: 'NZ' },
            { country: 'Norway', code: 'NO' },
            { country: 'Poland', code: 'PL' },
            { country: 'Portugal', code: 'PT' },
            { country: 'Romania', code: 'RO' },
            { country: 'Singapore', code: 'SG' },
            { country: 'Slovakia', code: 'SK' },
            { country: 'Slovenia', code: 'SI' },
            { country: 'Spain', code: 'ES' },
            { country: 'Sweden', code: 'SE' },
            { country: 'Switzerland', code: 'CH' },
            { country: 'United Kingdom', code: 'GB' },
            { country: 'United States', code: 'US' },
        ];
    }
    Object.defineProperty(StripePaymentModalComponent.prototype, "months", {
        get: function () {
            return months;
        },
        enumerable: true,
        configurable: true
    });
    StripePaymentModalComponent.prototype.ngOnInit = function () {
        this.stripe = Stripe(this.stripeId);
        this.initForm();
        this.createStripeCardElement();
    };
    StripePaymentModalComponent.prototype.open = function () {
        this.modal.open();
    };
    StripePaymentModalComponent.prototype.onModalOpen = function () {
        this.alert.closeAlert();
        this.form.reset();
    };
    StripePaymentModalComponent.prototype.initForm = function () {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            address: [''],
            city: [''],
            state: [''],
            zip: ['', Validators.required],
            country: ['US'],
        });
        this.years = generateFutureYears();
    };
    StripePaymentModalComponent.prototype.showStateField = function () {
        return includes(['AU', 'US'], this.form.get('country').value);
    };
    StripePaymentModalComponent.prototype.zipLabel = function () {
        switch (this.form.get('country').value) {
            case 'US':
                return 'ZIP';
            case 'GB':
                return 'Postcode';
            case 'AU':
                return 'Postcode';
            default:
                return 'Postal Code';
        }
    };
    StripePaymentModalComponent.prototype.createStripeCardElement = function () {
        var _this = this;
        var elements = this.stripe.elements();
        var style = {
            base: {
                iconColor: '#666ee8',
                color: '#31325f',
                fontWeight: 400,
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '15px',
                '::placeholder': {
                    color: '#aab7c4',
                },
                ':-webkit-autofill': {
                    color: '#666ee8',
                },
            },
        };
        this.card = elements.create('card', { style: style, hidePostalCode: true });
        this.card.mount('#card-element');
        this.card.on('change', function (_a) {
            var complete = _a.complete, error = _a.error;
            _this.isCardComplete = complete;
            _this.cardError = error;
        });
    };
    StripePaymentModalComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.submitting) {
            return;
        }
        if (this.form.invalid) {
            this.alert.newAlert('Please fill out required fields', 'danger');
            this.showValidation = true;
            return;
        }
        if (!this.isCardComplete) {
            this.alert.newAlert('Please fill out card information', 'danger');
            this.showValidation = true;
            this.cardError = { message: 'Please fill out card information' };
            return;
        }
        this.alert.newAlert('Submitting...', 'info');
        this.submitting = true;
        this.eventsService
            .postEntity(this.eventId, this.entity, __assign({}, this.payload, { processor: 'stripe' }))
            .subscribe(function (res) {
            _this.stripe
                .confirmCardPayment(res.payment.id, {
                payment_method: {
                    card: _this.card,
                    billing_details: {
                        name: _this.form.get('name').value,
                        address: {
                            line1: _this.form.get('address').value,
                            postal_code: _this.form.get('zip').value,
                        },
                    },
                },
                shipping: {
                    name: name,
                    address: {
                        line1: _this.form.get('address').value,
                        city: _this.form.get('city').value,
                        postal_code: _this.form.get('zip').value,
                        state: _this.form.get('state').value,
                    },
                },
            })
                .then(function (stripeRes) {
                _this.submitting = false;
                _this.handleStripeResponse(stripeRes);
            })
                .catch(function () {
                // this should never happen
                _this.alert.newAlert('Something went wrong.', 'danger');
            });
        }, function (err) {
            _this.alert.newAlert((err && err.error && err.error.message) ||
                'There was an error in submitting your payment.', 'danger');
        });
    };
    StripePaymentModalComponent.prototype.handleStripeResponse = function (_a) {
        var paymentIntent = _a.paymentIntent, error = _a.error;
        if (error && error.type === 'validation_error') {
            this.alert.newAlert('Please check the form again and try resubmitting', 'danger');
        }
        else if (error) {
            this.alert.newAlert(error.message, 'danger');
        }
        else if (paymentIntent.status === 'succeeded') {
            // Success! Payment is confirmed. Update the interface to display the confirmation screen.
            // Update the note about receipt and shipping (the payment has been fully confirmed by the bank).
            this.resetForm();
            this.modal.close();
            this.success.next("\n\t\t\t\t<p style=\"margin-bottom: 10px;\">Thank you for registering.</p>\n\t\t\t\t<p>A confirmation email from ez-events.com has been sent to the email address provided. Please email info@ez-events.com if you do not find this in your inbox or SPAM/Junk Mail Folder.</p>\n\t\t\t");
        }
        else if (paymentIntent.status === 'processing') {
            // Success! Now waiting for payment confirmation. Update the interface to display the confirmation screen.
            // Update the note about receipt and shipping (the payment is not yet confirmed by the bank).
            this.resetForm();
            this.modal.close();
            this.success.next("\n\t\t\t\t<p>Thank you for registering.</p>\n\t\t\t\t<p>We\u2019ll send your receipt as soon as your payment is confirmed.</p>\n\t\t\t");
        }
        else {
            // Payment has failed.
            this.alert.newAlert('Payment has failed.', 'danger');
        }
    };
    StripePaymentModalComponent.prototype.resetForm = function () {
        this.submitting = false;
        this.card.clear();
        this.isCardComplete = false;
        this.cardError = null;
        this.form.reset();
        this.alert.closeAlert();
    };
    StripePaymentModalComponent.prototype.getCardMask = function (formControl) {
        return getCreditCardMask(this.form.get(formControl).value);
    };
    return StripePaymentModalComponent;
}());
export { StripePaymentModalComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-registration-container.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../../shared/navbar/navbar.component";
import * as i4 from "../services/session-service/session.service";
import * as i5 from "../services/profile-service/profile.service";
import * as i6 from "../services/build.service";
import * as i7 from "@angular/router";
import * as i8 from "./event-registration-container.component";
var styles_EventRegistrationContainerComponent = [i0.styles];
var RenderType_EventRegistrationContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventRegistrationContainerComponent, data: {} });
export { RenderType_EventRegistrationContainerComponent as RenderType_EventRegistrationContainerComponent };
export function View_EventRegistrationContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eze-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 245760, null, 0, i3.NavbarComponent, [i4.SessionService, i5.ProfileService, i6.BuildService], { enableLogout: [0, "enableLogout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_EventRegistrationContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eze-event-registration-container", [], null, null, null, View_EventRegistrationContainerComponent_0, RenderType_EventRegistrationContainerComponent)), i1.ɵdid(1, 114688, null, 0, i8.EventRegistrationContainerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventRegistrationContainerComponentNgFactory = i1.ɵccf("eze-event-registration-container", i8.EventRegistrationContainerComponent, View_EventRegistrationContainerComponent_Host_0, {}, {}, []);
export { EventRegistrationContainerComponentNgFactory as EventRegistrationContainerComponentNgFactory };

import { Component, OnInit, Input } from '@angular/core';

import { Event, EventDocument } from '../../events/interfaces';
import { ProfileService } from '../../core/services/profile-service/profile.service';

interface EventCard extends Event {
	deadlinesArr?: {
		registrationType: string;
		deadline: string;
	}[];
}

@Component({
	selector: 'eze-my-event-card',
	templateUrl: './my-event-card.component.html',
	styleUrls: ['./my-event-card.component.css'],
})
export class MyEventCardComponent implements OnInit {
	_event: EventCard;
	@Input()
	set event(input: EventCard) {
		this._event = { ...input };
		this._event.deadlinesArr = Object.keys(input.deadlines).map((key) => {
			return {
				registrationType: key,
				deadline: input.deadlines[key],
			};
		});
	}

	@Input()
	showDashboardLink = false;

	@Input()
	showDayOfLink = false;

	get event(): EventCard {
		return this._event;
	}

	get documents(): EventDocument[] {
		return this._event.documents || [];
	}

	get divisionSheetsHref(): string {
		return `/api/v1/ez/events/${this.event.code}/division_sheets`;
	}

	constructor(private profileService: ProfileService) {}

	ngOnInit() {}

	getRegistrationLink(deadline) {
		return `${window.location.origin}/events/${this.event.code}/registrations/${deadline.registrationType}`;
	}

	copyInputMessage(inputElement: HTMLInputElement) {
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);
	}

	capFirst(str: string) {
		return str[0].toUpperCase() + str.slice(1);
	}
}

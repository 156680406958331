import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
	selector: 'eze-modal',
	templateUrl: './ez-modal.component.html',
	styleUrls: ['./ez-modal.component.css'],
})
export class EzModalComponent implements OnInit {
	@Input()
	canDismiss = true;

	private _isOpen = false;

	get isOpen() {
		return this._isOpen;
	}

	@ViewChild('modalContainer', { static: false })
	modalContainerElement: ElementRef;

	constructor() {}

	ngOnInit() {}

	handleOutsideClick(event: MouseEvent) {
		if (!this.canDismiss) {
			return;
		}
		if (this.modalContainerElement.nativeElement === event.target) {
			this._isOpen = false;
		}
	}

	open() {
		this._isOpen = true;
	}

	close() {
		this._isOpen = false;
	}
}

import { OnInit, EventEmitter, OnDestroy, } from '@angular/core';
import { SessionService } from '../../core/services/session-service/session.service';
import { ProfileService } from '../../core/services/profile-service/profile.service';
import { BuildService } from '../../core/services/build.service';
import { EzModalComponent } from '../ez-modal';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(sessionService, profileService, buildService) {
        var _this = this;
        this.sessionService = sessionService;
        this.profileService = profileService;
        this.buildService = buildService;
        this.title = '';
        this.enableMenu = false;
        this.enableLogout = false;
        this.events = null;
        this.logoutSuccess = new EventEmitter();
        this.showHamburger = false;
        this.showProfileDropdown = false;
        this.showEventDropdown = false;
        this.pollInterval = null;
        this.currentBuild = null;
        this.onWindowFocus = function () {
            _this.getBuild();
        };
    }
    Object.defineProperty(NavbarComponent.prototype, "isLoggedIn", {
        get: function () {
            return this.sessionService.isLoggedIn;
        },
        enumerable: true,
        configurable: true
    });
    NavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        window.addEventListener('focus', this.onWindowFocus);
        this.pollInterval = setInterval(function () {
            _this.getBuild();
        }, 120000);
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        this.stopPolling();
        window.removeEventListener('focus', this.onWindowFocus);
    };
    NavbarComponent.prototype.stopPolling = function () {
        if (this.pollInterval) {
            clearInterval(this.pollInterval);
        }
    };
    NavbarComponent.prototype.getBuild = function () {
        var _this = this;
        this.buildService.getBuild().subscribe(function (_a) {
            var build = _a.build;
            if (_this.currentBuild && _this.currentBuild !== build) {
                _this.refreshModal.open();
                _this.stopPolling();
                return;
            }
            _this.currentBuild = build;
        }, function (err) { });
    };
    NavbarComponent.prototype.refreshPage = function () {
        location.reload();
    };
    NavbarComponent.prototype.clickHamburger = function () {
        if (document.body.className.indexOf('hidden-menu') !== -1) {
            document.body.className = document.body.className.replace(/hidden-menu\b/, '');
        }
        else {
            document.body.className += ' hidden-menu';
        }
    };
    NavbarComponent.prototype.closeProfileDropdownIfOpen = function () {
        if (this.showProfileDropdown) {
            this.showProfileDropdown = false;
        }
    };
    NavbarComponent.prototype.closeEventDropdownIfOpen = function () {
        if (this.showEventDropdown) {
            this.showEventDropdown = false;
        }
    };
    NavbarComponent.prototype.logout = function () {
        var _this = this;
        this.profileService
            .logoutUser()
            .subscribe(function () { return _this.logoutSuccess.emit(); });
    };
    return NavbarComponent;
}());
export { NavbarComponent };

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResponse } from '../../shared/interfaces/ListResponse.interface';

export abstract class EntityService<T> {
	abstract url: string;
	abstract entity: string;

	constructor(public ezHttp: HttpClient) {}

	generateBaseUrl(eventId): string {
		return `${this.url}/${eventId}/${this.entity}`;
	}

	getEventEntityList(
		eventId: string,
		limit = '25',
		offset = '0',
		queryParams = {}
	): Observable<ListResponse> {
		return this.ezHttp.get<ListResponse>(
			`${this.generateBaseUrl(eventId)}?limit=${limit}&offset=${offset}`,
			{
				params: {
					...queryParams,
					limit,
					offset,
				},
			}
		);
	}

	getEventEntityDetails(eventId: string, entityId: string): Observable<T> {
		return this.ezHttp
			.get<T>(`${this.generateBaseUrl(eventId)}/${entityId}`)
			.pipe(
				map((entity) => {
					return {
						...entity,
						school_id: entity['school'] && entity['school']['id'],
					};
				})
			);
	}

	updateEventEntity(
		eventId: string,
		entityId: string,
		payload: T
	): Observable<T> {
		return this.ezHttp.put<T>(
			`${this.generateBaseUrl(eventId)}/${entityId}`,
			payload
		);
	}

	createEventEntity(eventId: string, payload: T): Observable<T> {
		return this.ezHttp.post<T>(`${this.generateBaseUrl(eventId)}`, payload);
	}

	removeEventEntity(eventId: string, entityId: string): Observable<T> {
		return this.ezHttp.delete<T>(
			`${this.generateBaseUrl(eventId)}/${entityId}`
		);
	}

	resendConfirmationEmail(eventId: string, entityId: string) {
		return this.ezHttp.post(
			`${this.generateBaseUrl(eventId)}/${entityId}/confirmation`,
			null
		);
	}
}

import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'eze-modal-footer',
	template: '<ng-content></ng-content>',
	styles: []
})
export class ModalFooterComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
